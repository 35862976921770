import React, { useState, useEffect, useRef } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import useResizeObserver from "use-resize-observer";
import { getGradient } from "../../../utills/getGradient";

const BudgetResourceWidget = ({ itemStyles, height, width, widgetId }) => {
  const dispatch = useDispatch();

  const mainRef = useRef(null);

  const joinRef = useRef(null);
  const joinDimensions = useResizeObserver({ ref: joinRef });

  const data = [
    {
      id: 1,
      name: "Monthly<br/>Budget<br/>Plan",
      url: `${process.env.REACT_APP_BASE_URL}/assets/pdf/monthly_budget.xls`,
    },
    {
      id: 2,
      name: "Weekly<br/>Budget<br/>Plan",
      url: `${process.env.REACT_APP_BASE_URL}/assets/pdf/weekly_budget.xls`,
    },
    {
      id: 3,
      name: "Financial<br/>Assistance<br/>Plan",
      url: "https://www.ramseysolutions.com/tools?snid=free-tools",
    },
    {
      id: 4,
      name: "Contact<br/>Redefine<br/>Finance",
      url: "javascript:;",
    },
  ];

  useEffect(() => {
    if (joinDimensions) {
      setTextHeight(joinDimensions.height);
      setTextWidth(joinDimensions.width);
    }
  }, [joinDimensions]);

  const [image, setImage] = useState("");

  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [color, setColor] = useState("");
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 34,
    isDefaultFont: 1,
  });
  const [textWidth, setTextWidth] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [border, setBorder] = useState("");
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const [defaultCorners, setDefaultCorners] = useState("true");
  const { default_widget_colors, mainColor } = universalStyles;

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          // console.log("IMG HERE>", itemStyles.background_image);
          setImage(itemStyles.background_image);
        }
      }
      // setImage(itemStyles.background_image);
      setTitle(itemStyles.title);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      setDefaultCorners(itemStyles.defaultCorners);
      setLink(itemStyles.widget_link);
      setTextWidth(itemStyles.text_width);
      setTextHeight(itemStyles.text_height);
      setBorder(itemStyles.border);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
    }
  }, [itemStyles]);

  const [isDefault, setDefault] = useState(0);

  const [imagePosition, setImagePosition] = useState("cover");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  return (
    <div
      id="joinGroup"
      ref={mainRef}
      className={`joinGroup`}
      style={{
        flexDirection: "column",
        justifyContent: "flex-start",
        ...((image == "0" || image == null || image == false) && {
          background: `${
            isDefault && default_widget_colors.groups
              ? default_widget_colors.groups.is_default === "0"
                ? default_widget_colors.groups.color
                : default_widget_colors.groups.is_default === "1"
                ? mainColor
                : getGradient(color)
              : getGradient(color)
          }`,
        }),
        ...(image !== "0" &&
          image !== null &&
          image !== false && {
            backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${image}`,
            backgroundPosition: "center",
            backgroundRepeat: imageRepeat,
            backgroundSize: imagePosition,
          }),
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
      }}
    >
      {/*  */}
      <div className="d-flex col-md-12 mb-4">
        <h2 style={{ color: "#fff", fontWeight: "bold" }}>
          Budgeting Resources
        </h2>
      </div>
      <div
        className="d-flex col-md-12 align-items-center justify-content-between flex-wrap"
        style={{
          height: "80%",
        }}
      >
        {data &&
          data.map((item) => {
            return (
              <div
                onClick={() => {
                  if (item.id == 4) return;
                  window.open(item.url, "_blank");
                }}
                className="d-flex flex-column justify-content-center"
                style={{
                  border: "2px solid #fff",
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  width: "49%",
                  paddingLeft: 20,
                  height: "45%",
                  cursor: "pointer",
                }}
              >
                <h3 className="budget-resource-h3">
                  {item.name.split("<br/>").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </h3>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BudgetResourceWidget;
