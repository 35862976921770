import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
import "../home/styles.css";
//Assets
// import Assets from "../../assets/images";
//Actions
import { getStyling } from "../../store/slices/bodyStyling";
//Components
import SortableGrid from "../../components/GridLayout/SortableGrid";

function KidsPage(props) {
  const { darkMode } = props;
  const dispatch = useDispatch();

  const data = useSelector((state) => state.bodyStyling.styling);
  const loading = useSelector((state) => state.bodyStyling.isLoading);

  useEffect(() => {
    dispatch(getStyling(7));
  }, []);

  return (
    <div
      className={
        darkMode ? "rightWrapMain darkMode" : "rightWrapMain lightMode"
      }
    >
      <div className="rightInnerWrap">
        <div className="sec2">
          <div className="container">
            {loading ? (
              <Circles
                height="50"
                width="50"
                color="transparent"
                ariaLabel="circles-loading"
                wrapperStyle={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SortableGrid widgetsOrder={data.homepageOrder} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default KidsPage;
