import React, { useState, useEffect, useRef, useCallback } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getGradient } from "../../../utills/getGradient";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";
import ReactDOM from "react-dom";
import { ArrowRight, CalendarDays } from "lucide-react";
import XHR from "../../../utills/XHR";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Circles } from "react-loader-spinner";
import toast from "react-hot-toast";
import moment from "moment";

const GivingWidget = ({ itemStyles, width, height, widgetId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.userData);
  const [border, setBorder] = useState("");
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const { default_widget_colors, mainColor } = universalStyles;
  const [defaultCorners, setDefaultCorners] = useState("true");
  const [giftType, setGiftType] = useState(1);
  const [frequency, setFrequency] = useState("Weekly");

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      buttonFont.isDefaultFont == 1 &&
        setButtonFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (itemStyles.background_color) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      }
      setBtnTxt(itemStyles.button_text);
      // setBtnBg(itemStyles.button_background_color);
      if (itemStyles.button_background_color) {
        try {
          const bgstyle = JSON.parse(itemStyles.button_background_color);
          setBtnBg(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.button_background_color;
          setBtnBg(bgstyle);
        }
      }
      setTxtColor(itemStyles.button_text_color);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      if (itemStyles.button_font_style) {
        try {
          const buttonStyle = JSON.parse(itemStyles.button_font_style);
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
        } catch (error) {
          const buttonStyle = itemStyles.button_font_style;
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
          console.error("Error parsing heading title font style:", error);
        }
      }

      setLink(itemStyles.widget_link);
      setBtnLink(itemStyles.button_link);
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
    }
  }, [itemStyles]);

  const renderCalendarContainer = ({ children }) => {
    return ReactDOM.createPortal(
      <CalendarContainer>{children}</CalendarContainer>,
      document.body
    );
  };

  const [isDefaultButton, setDefaultButton] = useState(0);
  const [buttonFont, setButtonFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 18,
    isDefaultFont: 1,
  });

  const [isDefault, setDefault] = useState(0);
  const [color, setColor] = useState("#f8acac");
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "frontage",
    size: 22,
    isDefaultFont: 1,
  });
  const [btnBg, setBtnBg] = useState("");
  const [btnTxt, setBtnTxt] = useState("");
  const [txtColor, setTxtColor] = useState("");
  const [link, setLink] = useState("");
  const [btnlink, setBtnLink] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [amount, setAmount] = useState(0);
  const [isReoccuring, setReoccuring] = useState(false);

  const [givingLoader, setGivingLoader] = useState(false);
  const [defaultCard, setDefaultCard] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [totalGoal, setTotalGoal] = useState(0);
  const [paymentLoader, setPaymentLoader] = useState(false);

  const hostname = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (user?.id && user?.api_token) {
      requestGiving(user.id, user.api_token);
    }
  }, [user]);

  // Request Giving Data
  const requestGiving = (userId, token) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    };
    setGivingLoader(true);
    XHR(`${hostname}/api/v1/getGiving?user_id=${userId}`, options)
      .then((resp) => {
        if (resp.data.success) {
          const { default_card, giving } = resp.data;
          default_card && setDefaultCard(default_card);
          if (giving) {
            if (giving.goal.percentage) {
              setPercentage(giving.goal.percentage);
            }
            if (giving.goal.paid_amount) {
              setPaidAmount(giving.goal.paid_amount);
            }
            if (giving.goal.amount) {
              setTotalGoal(giving.goal.amount);
            }
          }
        }
      })
      .catch((e) => {
        console.log("ERR>", e);
      })
      .finally(() => {
        setGivingLoader(false);
        setAmount("");
      });
  };

  // Donate
  const donateNow = (amount, defaultCard, user) => {
    if (amount == 0) {
      toast.error("Enter amount", { id: "empty" });
      return;
    }

    const options = {
      method: "post",
      // @ts-ignore
      url: `${hostname}/api/v1/stripePostPay?amount=${amount}&gift=No&datepicker_date=${moment(
        new Date()
      ).format("YYYY-MM-DD")}&customer=${
        defaultCard.customer
      }&currency_code=USD&card_id=${defaultCard.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.api_token}`,
      },
    };
    setPaymentLoader(true);
    /*@ts-ignore */
    XHR(options)
      .then((resp) => {
        if (resp.data.ordStatus === "success") {
          toast.success(resp.data.message);
          setAmount(0);
          requestGiving(user.id, user?.api_token);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          toast.error(e.response.data.message, { duration: 4000 });
        }
        console.log("ERR>", e);
      })
      .finally(() => {
        setPaymentLoader(false);
      });
  };

  const LoggedOutView = useCallback(
    ({
      isDefault,
      default_widget_colors,
      mainColor,
      color,
      border,
      font,
      giftType,
      startDate,
      frequency,
      btnBg,
      buttonFont,
      btnTxt,
      universalStyles,
      amount,
    }) => {
      return (
        <div
          className={"pinkgradient"}
          style={{
            background: `${
              isDefault && default_widget_colors.giving
                ? default_widget_colors.giving.is_default === "0"
                  ? default_widget_colors.giving.color
                  : default_widget_colors.giving.is_default === "1"
                  ? mainColor
                  : getGradient(color)
                : getGradient(color)
            }`,
            // justifyContent: "space-between",
            // justifyContent: height == 8 ? "space-around" : "space-between",
            borderRadius:
              border === "round" ? 20 : border === "sharp" ? 0 : null,
          }}
        >
          <div className="d-flex ">
            <h2
              style={{
                color: font.color ? font.color : "white",
                textDecorationLine: font.isUnderlined ? "underline" : "none",
                fontWeight: font.isBold ? "bold" : "normal",
                fontStyle: font.isItalic ? "italic" : "normal",
                fontFamily: font.family,
                fontSize: Number(font.size),
              }}
              className="maintitle"
            >
              Give
            </h2>
          </div>

          <div className="welcomForm give-form" style={{ marginTop: 0 }}>
            <div className="d-flex col-md-12 amount-Input">
              <span style={{ fontSize: 13, color: "#fff" }}>$</span>
              <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="number"
                placeholder="Amount"
                style={{ border: "unset" }}
              />
            </div>
            {/* radio  button */}
            <div
              className="d-flex gap-2 mt-3"
              onClick={() => setGiftType(1)}
              style={{ cursor: "pointer" }}
            >
              <div className={`outerCircle outerCircleHide}`}>
                <div className={giftType == 1 ? "circleDot" : ""} />
              </div>
              <h6 className="gift-type">One Time Gift</h6>
            </div>
            <div
              className="d-flex gap-2"
              onClick={() => setGiftType(2)}
              style={{ cursor: "pointer" }}
            >
              <div className={`outerCircle outerCircleHide}`}>
                <div className={giftType == 2 ? "circleDot" : ""} />
              </div>
              <h6 className="gift-type">Reoccuring Gift</h6>
            </div>

            <div className="d-flex row gap-2 justify-content-between ">
              <div className="col-md-5">
                <ReactDatePicker
                  className="date-input"
                  popperContainer={renderCalendarContainer}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showIcon
                  minDate={new Date()}
                  isClearable
                  placeholderText="MM/DD/YYYY"
                  icon={<CalendarDays size={20} color="#fff" />}
                  closeOnScroll
                  autoFocus={false}
                  // disabled
                />
              </div>
              {/* Frequency */}
              {giftType == 2 && (
                <div className="col-md-6 d-flex column-gap-3 row-gap-1 flex-wrap align-items-end justify-content-end">
                  {["Weekly", "Bi-Weekly", "Monthly"].map((item) => {
                    return (
                      <div
                        className="d-flex col-md-3 align-items-center justify-content-center"
                        onClick={() => setFrequency(item)}
                        style={{
                          backgroundColor:
                            frequency === item ? "black" : "#fff",
                          paddingInline: 10,
                          // paddingBlock: 6,
                          minWidth: "fit-content",
                          cursor: "pointer",
                          height: 30,
                        }}
                      >
                        <span style={{ fontSize: 12, color: "#fb5656" }}>
                          {item}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {/* Give Btn */}
          <div
            className="d-flex align-items-center mt-3"
            style={{
              justifyContent: height == 8 ? "center" : "flex-end",
            }}
          >
            <div
              className="d-flex align-items-center submit-btn-give halffield"
              style={{
                justifyContent: height == 8 ? "center" : "flex-end",
              }}
            >
              <input
                style={{
                  background: getGradient(btnBg),
                  color: txtColor,
                  fontSize: Number(buttonFont.size),
                  fontFamily:
                    buttonFont.family === "default"
                      ? universalStyles.font
                      : buttonFont.family
                      ? buttonFont.family
                      : "sans-serif",
                  fontStyle: buttonFont.isItalic ? "italic" : "normal",
                  textDecorationLine: buttonFont.isUnderlined
                    ? "underline"
                    : "none",
                  fontWeight: buttonFont.isBold ? "bold" : "normal",
                  border: "unset",
                }}
                type="submit"
                value={btnTxt ? btnTxt : "Give Now"}
              />
            </div>
          </div>
        </div>
      );
    },
    []
  );

  const LoggedInView = useCallback(
    ({
      isDefault,
      default_widget_colors,
      mainColor,
      color,
      border,
      font,
      isReoccuring,
      btnBg,
      txtColor,
      buttonFont,
      universalStyles,
      btnTxt,
      amount,
      paymentLoader,
      givingLoader,
      percentage,
      totalGoal,
      paidAmount,
      defaultCard,
      user,
    }) => {
      return (
        <a
          style={{ textDecoration: "none" }}
          href={itemStyles?.widget_link}
          target="_blank"
        >
          <div
            className="pinkgradient"
            style={{
              // background: `${
              //   isDefault && default_widget_colors.giving
              //     ? default_widget_colors.giving.is_default === "0"
              //       ? default_widget_colors.giving.color
              //       : default_widget_colors.giving.is_default === "1"
              //       ? mainColor
              //       : getGradient(color)
              //     : getGradient(color)
              // }`,
              borderRadius:
                border === "round" ? 20 : border === "sharp" ? 0 : null,
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <div
              className=" welcomForm give-form topPart d-flex justify-content-center flex-column"
              style={{
                padding: 20,
                height: "45%",
                background: `${
                  isDefault && default_widget_colors.giving
                    ? default_widget_colors.giving.is_default === "0"
                      ? default_widget_colors.giving.color
                      : default_widget_colors.giving.is_default === "1"
                      ? mainColor
                      : getGradient(color)
                    : getGradient(color)
                }`,
                borderTopLeftRadius: border==='round'? 15:0,
                borderTopRightRadius: border==='round'? 15:0
              }}
            >
              <h2
                style={{
                  color: font.color ? font.color : "white",
                  textDecorationLine: font.isUnderlined ? "underline" : "none",
                  fontWeight: font.isBold ? "bold" : "normal",
                  fontStyle: font.isItalic ? "italic" : "normal",
                  fontFamily: font.family,
                  fontSize: Number(font.size),
                }}
                className="maintitle"
              >
                Give
              </h2>
              <div className="d-flex align-items-center justify-content-between row">
                <div className="d-flex col-md-5 amount-Input">
                  <span style={{ fontSize: 13, color: "#fff" }}>$</span>
                  <input
                    disabled={paymentLoader || givingLoader}
                    onClick={(e) => e.preventDefault()}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    type="number"
                    placeholder="Amount"
                    style={{ border: "unset" }}
                  />
                </div>
                <div
                  className="d-flex col-md-7 align-items-center submit-btn-give justify-content-end"
                  style={{
                    justifyContent: height == 8 ? "center" : "flex-end",
                  }}
                >
                  {paymentLoader ? (
                    <Circles
                      height="40"
                      width="40"
                      color="#fff"
                      ariaLabel="circles-loading"
                      wrapperClass="submit-btn-give"
                      visible={true}
                    />
                  ) : (
                    <input
                      disabled={paymentLoader || givingLoader}
                      onClick={(e) => {
                        donateNow(amount, defaultCard, user);
                        e.preventDefault();
                      }}
                      style={{
                        background: getGradient(btnBg),
                        color: txtColor,
                        fontSize: Number(buttonFont.size),
                        fontFamily:
                          buttonFont.family === "default"
                            ? universalStyles.font
                            : buttonFont.family
                            ? buttonFont.family
                            : "sans-serif",
                        fontStyle: buttonFont.isItalic ? "italic" : "normal",
                        textDecorationLine: buttonFont.isUnderlined
                          ? "underline"
                          : "none",
                        fontWeight: buttonFont.isBold ? "bold" : "normal",
                        border: "unset",
                      }}
                      type="submit"
                      value={btnTxt ? btnTxt : "Give"}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* white part */}
            <div
              className="d-flex align-items-center"
              style={{
                backgroundColor: "#ffffff",
                borderBottomRightRadius: border==='round'? 15:0,
                borderBottomLeftRadius: border==='round'? 15:0,
                height: "55%",
                justifyContent: "space-around",
              }}
            >
              <div className="col-md-2" style={{ height: "75%" }}>
                <Progress.Line
                  showInfo={false}
                  strokeWidth={40}
                  vertical
                  /* @ts-ignore */
                  percent={percentage}
                  strokeColor="#fb5f5f"
                  style={{ opacity: 0.9 }}
                />
              </div>
              <div className="d-flex flex-column col-md-10 align-items-center justify-content-center">
                {/* @ts-ignore */}
                {givingLoader ? (
                  <Skeleton baseColor="#e8e8ec" height={20} width={100} />
                ) : (
                  <h1
                    style={{
                      color: "#fb5f5f",
                      fontWeight: "600",
                      letterSpacing: 4,
                      margin: 0,
                      opacity: 0.9,
                    }}
                  >
                    {Number(percentage).toFixed(0)}%
                  </h1>
                )}
                {givingLoader ? (
                  <Skeleton baseColor="#e8e8ec" height={10} width={100} />
                ) : (
                  <h4
                    style={{
                      color: "#fb5f5f",
                      fontWeight: "400",
                      letterSpacing: 4,
                      margin: 0,
                    }}
                  >
                    {`$${paidAmount} | $${Number(totalGoal).toFixed(0)}`}
                  </h4>
                )}

                <p
                  style={{
                    color: "#000000",
                    fontSize: 12,
                    margin: 0,
                    lineHeight: "10px",
                    fontWeight: "600",
                    marginTop: 10,
                  }}
                >
                  YOUR GIVING GOAL
                </p>
              </div>
            </div>
          </div>
        </a>
        // <a
        //   style={{ textDecoration: "none" }}
        //   href={itemStyles?.widget_link}
        //   target="_blank"
        // >
        //   <div
        //     className={"pinkgradient"}
        //     style={{
        //       background: `${
        //         isDefault && default_widget_colors.giving
        //           ? default_widget_colors.giving.is_default === "0"
        //             ? default_widget_colors.giving.color
        //             : default_widget_colors.giving.is_default === "1"
        //             ? mainColor
        //             : getGradient(color)
        //           : getGradient(color)
        //       }`,
        //       borderRadius:
        //         border === "round" ? 20 : border === "sharp" ? 0 : null,
        //       justifyContent: "space-between",
        //     }}
        //   >
        //     <div className="d-flex ">
        //       <h2
        //         style={{
        //           color: font.color ? font.color : "white",
        //           textDecorationLine: font.isUnderlined ? "underline" : "none",
        //           fontWeight: font.isBold ? "bold" : "normal",
        //           fontStyle: font.isItalic ? "italic" : "normal",
        //           fontFamily: font.family,
        //           fontSize: Number(font.size),
        //         }}
        //         className="maintitle"
        //       >
        //         Giving
        //       </h2>
        //     </div>
        //     <div className="welcomForm give-form" style={{ marginTop: 0 }}>
        //       <div className="d-flex row align-items-center justify-content-between gap-2">
        //         <div className="d-flex col-md-5 amount-Input">
        //           <span style={{ fontSize: 13, color: "#fff" }}>$</span>
        //           <input
        //             onClick={(e) => {
        //               e.preventDefault();
        //             }}
        //             value={amount}
        //             onChange={(e) => setAmount(e.target.value)}
        //             type="number"
        //             placeholder="Amount"
        //             style={{ border: "unset" }}
        //           />
        //         </div>
        //         <div class="togSwichCls d-flex col-md-5 align-items-center justify-content-end gap-2">
        //           <label class="switch">
        //             <input
        //               onClick={(e) => {
        //                 e.stopPropagation();
        //               }}
        //               type="checkbox"
        //               id="yesCheck"
        //               class="gift_case"
        //               value={isReoccuring}
        //               onChange={(e) => setReoccuring(e.target.checked)}
        //             />
        //             <span class="slider"></span>
        //             <input type="hidden" name="gift" id="gift" value="No" />
        //           </label>
        //           <span
        //             style={{
        //               color: "#fff",
        //               fontWeight: "600",
        //               fontSize: 12,
        //               marginTop: 6,
        //             }}
        //           >
        //             Reoccurring Gift
        //           </span>
        //         </div>
        //       </div>

        //       {/* Give Btn */}
        //       <div className="d-flex row align-items-center mt-3">
        //         <div className="col-md-5">
        //           <span style={{ color: "#fff" }}>**** **** **** 1234</span>
        //         </div>
        //         <div
        //           className="d-flex col-md-7 align-items-center submit-btn-give just-fy-content-end"
        //           style={{
        //             justifyContent: height == 8 ? "center" : "flex-end",
        //           }}
        //         >
        //           <input
        //             onClick={(e) => {
        //               e.preventDefault();
        //             }}
        //             style={{
        //               background: getGradient(btnBg),
        //               color: txtColor,
        //               fontSize: Number(buttonFont.size),
        //               fontFamily:
        //                 buttonFont.family === "default"
        //                   ? universalStyles.font
        //                   : buttonFont.family
        //                   ? buttonFont.family
        //                   : "sans-serif",
        //               fontStyle: buttonFont.isItalic ? "italic" : "normal",
        //               textDecorationLine: buttonFont.isUnderlined
        //                 ? "underline"
        //                 : "none",
        //               fontWeight: buttonFont.isBold ? "bold" : "normal",
        //               border: "unset",
        //             }}
        //             type="submit"
        //             value={btnTxt ? btnTxt : "Give Now"}
        //           />
        //         </div>
        //       </div>
        //     </div>
        //     <div className="d-flex justify-content-end">
        //       <div
        //         onClick={(e) => {
        //           e.preventDefault();
        //         }}
        //         className="d-flex align-items-center justify-content-center"
        //         style={{
        //           height: 40,
        //           width: 40,
        //           borderRadius: 10,
        //           backgroundColor: "#f3f2f2a1",
        //         }}
        //       >
        //         <ArrowRight size={20} color="#fb5454" strokeWidth={5} />
        //       </div>
        //     </div>
        //   </div>
        // </a>
      );
    },
    []
  );

  return user?.api_token ? (
    <LoggedInView
      border={border}
      btnBg={btnBg}
      btnTxt={btnTxt}
      buttonFont={buttonFont}
      color={color}
      default_widget_colors={default_widget_colors}
      font={font}
      isDefault={isDefault}
      isReoccuring={isReoccuring}
      mainColor={mainColor}
      txtColor={txtColor}
      universalStyles={universalStyles}
      amount={amount}
      paymentLoader={paymentLoader}
      percentage={percentage}
      givingLoader={givingLoader}
      paidAmount={paidAmount}
      totalGoal={totalGoal}
      defaultCard={defaultCard}
      user={user}
    />
  ) : (
    <LoggedOutView
      border={border}
      btnBg={btnBg}
      btnTxt={btnTxt}
      buttonFont={buttonFont}
      color={color}
      default_widget_colors={default_widget_colors}
      font={font}
      frequency={frequency}
      giftType={giftType}
      isDefault={isDefault}
      mainColor={mainColor}
      startDate={startDate}
      universalStyles={universalStyles}
      amount={amount}
    />
  );
};

export default GivingWidget;
