// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  padding: 8px;
  min-height: 800px;
  z-index: 1;
}

@media (max-width: 700px) {
  .layout {
    padding: 0 !important;
  }
}

.react-grid-layout {
  position: unset !important;
  width: 100%;
}

@media (min-width: 1706px) {
  .react-grid-layout,.serve-block {
    width: 90% !important;
  }
}

@media (min-width: 1920px) {
  .react-grid-layout,.serve-block {
    width: 85% !important;
  }
}

@media (min-width: 2048px) {
  .react-grid-layout,.serve-block {
    width: 75% !important;
  }
}

@media (min-width: 2304px) {
  .react-grid-layout,.serve-block {
    width: 60% !important;
  }
}

@media (min-width: 3072px) {
  .react-grid-layou,.serve-blockt {
    width: 55% !important;
  }
}

.layout > div {
  font-size: 18px;
  display: block;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  /* z-index: 1 !important; */
}

.confirmation-dialog {
  box-shadow: 2px 2px 2px 2px rgb(84 75 75 / 50%);
}

.popup-content {
  width: unset !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/GridLayout/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE,0BAA0B;EAC1B,WAAW;AACb;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".layout {\n  padding: 8px;\n  min-height: 800px;\n  z-index: 1;\n}\n\n@media (max-width: 700px) {\n  .layout {\n    padding: 0 !important;\n  }\n}\n\n.react-grid-layout {\n  position: unset !important;\n  width: 100%;\n}\n\n@media (min-width: 1706px) {\n  .react-grid-layout,.serve-block {\n    width: 90% !important;\n  }\n}\n\n@media (min-width: 1920px) {\n  .react-grid-layout,.serve-block {\n    width: 85% !important;\n  }\n}\n\n@media (min-width: 2048px) {\n  .react-grid-layout,.serve-block {\n    width: 75% !important;\n  }\n}\n\n@media (min-width: 2304px) {\n  .react-grid-layout,.serve-block {\n    width: 60% !important;\n  }\n}\n\n@media (min-width: 3072px) {\n  .react-grid-layou,.serve-blockt {\n    width: 55% !important;\n  }\n}\n\n.layout > div {\n  font-size: 18px;\n  display: block;\n}\n\n.react-grid-item.react-grid-placeholder {\n  background: red;\n  opacity: 0.2;\n  transition-duration: 100ms;\n  /* z-index: 1 !important; */\n}\n\n.confirmation-dialog {\n  box-shadow: 2px 2px 2px 2px rgb(84 75 75 / 50%);\n}\n\n.popup-content {\n  width: unset !important;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n\n.react-datepicker-popper {\n  z-index: 9999 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
