import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
// Assets
import Hamburger from "hamburger-react";

export default function LogoutHeader() {
  const hostname = "redefine.church";
  const [isOpen, setOpen] = useState(false);

  return (
    <header className="newHeader blueHeader">
      <Toaster position="top-center" />
      <div className="container-fluid">
        <div className="row d-flex align-items-center justify-content-between logout-mian-header ">
          <div className="d-flex col-md-3 logo-main mainHeader">
            <a href="/">
              <img
                src="https://images.ctfassets.net/1itkm9rji8jb/48jN6KXbjOANg70fZ2oHFE/66ead803c36b23e6752a0a2602e659bc/logo__1_.png"
                alt="Logo"
              />
            </a>
          </div>
          {/* hamburger  */}
          <div className="col-md-3 logo-main header-Logo">
            <div style={{ width: 60 }} />
            <a href="/">
              <img
                src="https://images.ctfassets.net/1itkm9rji8jb/48jN6KXbjOANg70fZ2oHFE/66ead803c36b23e6752a0a2602e659bc/logo__1_.png"
                alt="Logo"
              />
            </a>
            <div>
              <Hamburger toggled={isOpen} toggle={setOpen} />
              <div className={`mobileMenuDesign ${isOpen ? "open" : ""}`}>
                <div className="admin-dashboard-left">
                  <ul style={{rowGap:10}} >
                    <li>
                      <a
                        href={`/`}
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                      >
                        <span className="header-heading">Home</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`/about-us`}
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                      >
                        <span className="header-heading">About Us</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`/serve`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <span className="header-heading">Serve</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`/group`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <span className="header-heading">Groups</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`/kids`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <span className="header-heading">Kidz</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={`/giving`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        <span className="header-heading">Give</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex col-md-8 align-items-center justify-content-end gap-4 header-shortcut-nav ">
            <a
              href={`/`}
              style={{
                textDecoration: "none",
                color: "white",
              }}
            >
              <span className="header-heading">Home</span>
            </a>
            <a
              href={`/about-us`}
              style={{
                textDecoration: "none",
                color: "white",
              }}
            >
              <span className="header-heading">About Us</span>
            </a>
            <a
              href={`/serve`}
              style={{ textDecoration: "none", color: "white" }}
            >
              <span className="header-heading">Serve</span>
            </a>
            <a
              href={`/group`}
              style={{ textDecoration: "none", color: "white" }}
            >
              <span className="header-heading">Groups</span>
            </a>
            <a
              href={`kids`}
              style={{ textDecoration: "none", color: "white" }}
            >
              <span className="header-heading">Kidz</span>
            </a>
            <a
              href={`/giving`}
              style={{ textDecoration: "none", color: "white" }}
            >
              <span className="header-heading">Give</span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
