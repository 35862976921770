import React, { useCallback, useEffect, useState } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getGradient } from "../../../utills/getGradient";
import { postRequest } from "../../../utills/requests";
import toast from "react-hot-toast";
import { Circles } from "react-loader-spinner";
import { Info } from "lucide-react";
import { loginSuccess, setUserProfile } from "../../../store/slices/loginSlice";
import PasswordHintModal from "../../../components/Modal/PasswordHintModal";
import { submitRequest } from "../../../store/slices/bodyStyling";

const ConnectWidget = ({ itemStyles, height, width, widgetId, x }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.userData);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const { default_widget_colors, mainColor } = universalStyles;
  const [imagePosition, setImagePosition] = useState("cover");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");
  const [isDefault, setDefault] = useState(0);

  const [image, setImage] = useState("");
  const [color, setColor] = useState(false);
  const [title, setTitle] = useState("");
  const [btnBg, setBtnBg] = useState("");
  const [btnTxt, setBtnTxt] = useState("");
  const [txtColor, setTxtColor] = useState("");
  const [link, setLink] = useState("");
  const [btnlink, setBtnLink] = useState("");
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "frontage",
    size: 34,
    isDefaultFont: 1,
  });
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [prayer, setPrayer] = useState("");

  const [prayerRequestLoader, setPrayerRequestLoader] = useState(false);
  const [yesNo, setYesNo] = useState("Yes");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfrirmPassword] = useState("");
  const [passwordSectionVisible, setPasswordSectionVisible] = useState(false);
  const [userId, setUserId] = useState("");
  const [isModalVisible, showModal] = useState(false);

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      buttonFont.isDefaultFont == 1 &&
        setButtonFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  const [isDefaultButton, setDefaultButton] = useState(0);
  const [buttonFont, setButtonFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 18,
    isDefaultFont: 1,
  });

  const [error, setError] = useState("");

  useEffect(() => {
    if (itemStyles) {
      // setImage(itemStyles.background_image);
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          setImage(itemStyles.background_image);
        }
      }

      setBtnTxt(itemStyles.button_text);
      setTitle(itemStyles.title);
      if (itemStyles.button_background_color) {
        try {
          const bgstyle = JSON.parse(itemStyles.button_background_color);
          setBtnBg(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.button_background_color;
          setBtnBg(bgstyle);
        }
      }

      setTxtColor(itemStyles.button_text_color);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }

      if (itemStyles.button_font_style) {
        try {
          const buttonStyle = JSON.parse(itemStyles.button_font_style);
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
        } catch (error) {
          const buttonStyle = itemStyles.button_font_style;
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
          console.error("Error parsing heading title font style:", error);
        }
      }

      setLink(itemStyles.widget_link);
      setBtnLink(itemStyles.button_link);
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
    }
  }, [itemStyles]);

  const sendPrayerRequest = async (prayer) => {
    if (prayer.trim().length == 0) return;
    setPrayerRequestLoader(true);
    try {
      const response = await postRequest({
        endpoint: "/getPrayerRequest",
        payload: { prayer_request: prayer },
      });
      if (response?.data?.status) {
        console.log("RESP>>", response);
        setPrayer("");
        toast.success("Prayer request sent.");
      }
    } catch (error) {
      console.log("PRAYER ERR>", error);
    } finally {
      setPrayerRequestLoader(false);
    }
  };

  const userRegister = async ({
    prayer,
    yesNo,
    phone,
    email,
    firstName,
    lastName,
  }) => {
    if (firstName.trim().length == 0) {
      setError("First name required");
      return;
    } else if (lastName.trim().length == 0) {
      setError("Last name required");
      return;
    } else if (email.trim().length == 0) {
      setError("Email required");
      return;
    }

    const data = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      email,
      first_time: yesNo === "Yes" ? 1 : 0,
      prayer_request: prayer,
    };
    setPrayerRequestLoader(true);
    try {
      const response = await postRequest({
        endpoint: "/userRegister",
        payload: data,
      });
      if (response?.data?.status) {
        console.log("RESP>>", response?.data);
        setUserId(response?.data?.userid);
        localStorage.setItem("api_token", response?.data?.api_token);
        setError("");
        toast.success("Subscribed successfully");
        setPasswordSectionVisible(true);
      }
    } catch (error) {
      console.log("Register ERR>", error?.response?.data?.errors);
      if (error?.response?.data?.errors?.email?.[0]) {
        setError(error.response.data.errors.email[0]);
      }
    } finally {
      setPrayerRequestLoader(false);
    }
  };

  const handlePassword = async ({
    password,
    confirmPassword,
    email,
    firstName,
    lastName,
    yesNo,
    phone,
  }) => {
    if (password.trim().length == 0) {
      setError("Enter password");
      return;
    } else if (confirmPassword.trim().length == 0) {
      setError("Enter confirmation password");
      return;
    } else if (password !== confirmPassword) {
      setError("Passwords does not match");
      return;
    } else if (password.length < 6) {
      setError("The password must be at least 6 characters.");
      return;
    }

    const data = {
      password: password,
      password_confirmation: confirmPassword,
      user_id: userId,
    };
    setPrayerRequestLoader(true);
    try {
      const response = await postRequest({
        endpoint: "/setPasswordWithoutLogin",
        payload: data,
      });
      if (response?.data?.status) {
        console.log("RESP>>", response.data);
        setError("");
        toast.success("Password set successfully", { id: "password set" });
        const data = {
          username: firstName.trim() + " " + lastName.trim(),
          password,
          email,
        };
        handleLogin(data, userId, yesNo, firstName, lastName, phone);
        setPasswordSectionVisible(false);
      }
    } catch (error) {
      console.log("Register ERR>", error?.response?.data?.errors);
      if (error?.response?.data?.errors?.password?.[0]) {
        setError(error.response.data.errors.password[0]);
      }
    } finally {
      setPrayerRequestLoader(false);
    }
  };

  const handleLogin = async (
    data,
    userId,
    yesNo,
    firstName,
    lastName,
    phone
  ) => {
    dispatch(submitRequest(true));
    try {
      const response = await postRequest({
        endpoint: "/loginReact",
        payload: data,
      });
      if (response?.data?.status === true) {
        dispatch(loginSuccess(response.data.user));
        dispatch(setUserProfile(response.data.user_profile));
        localStorage.setItem("accessToken", response?.data?.user?.api_token);
        toast.dismiss();
        toast.success("Logged In", { duration: 3000 });
      } else {
        console.log("ERR>", response?.data?.message);
      }
    } catch (error) {
      console.log("ERR>", error);
    } finally {
      dispatch(submitRequest(false));
    }
  };

  const createPassword = async (password, confirmPassword, user) => {
    if (password.trim().length == 0) {
      setError("Enter password");
      return;
    } else if (confirmPassword.trim().length == 0) {
      setError("Enter confirmation password");
      return;
    } else if (password !== confirmPassword) {
      setError("Passwords does not match");
      return;
    } else if (password.length < 6) {
      setError("The password must be at least 6 characters.");
      return;
    }
    setPrayerRequestLoader(true);
    try {
      const data = {
        password,
        password_confirmation: confirmPassword,
      };
      const response = await postRequest({
        endpoint: "/setPassword",
        payload: data,
      });
      if (response?.data?.status === true) {
        toast.success("Password created Suuccessfully");
        const userData = { ...user, withoutPassword: false };
        dispatch(loginSuccess(userData));
        setPasswordSectionVisible(false);
      } else {
        console.log("ERR>", response?.data);
      }
    } catch (error) {
      console.log("ERR>", error);
    } finally {
      setPrayerRequestLoader(false);
    }
  };

  const LogOutView = useCallback(
    ({
      firstName,
      lastName,
      email,
      phone,
      prayer,
      image,
      isDefault,
      mainColor,
      color,
      default_widget_colors,
      imagePosition,
      imageRepeat,
      border,
      font,
      universalStyles,
      title,
      yesNo,
      btnBg,
      buttonFont,
      txtColor,
      btnTxt,
      prayerRequestLoader,
      userRegister,
      error,
      password,
      confirmPassword,
      passwordSectionVisible,
      handlePassword,
      showModal,
      userId,
    }) => {
      return (
        <div
          className="bluegradientmain"
          style={{
            ...((image == "0" || image == null || image == false) && {
              background: `${
                isDefault && default_widget_colors.connect
                  ? default_widget_colors.connect.is_default === "0"
                    ? default_widget_colors.connect.color
                    : default_widget_colors.connect.is_default === "1"
                    ? mainColor
                    : getGradient(color)
                  : getGradient(color)
              }`,
            }),
            ...(image !== "0" &&
              image !== null &&
              image !== false && {
                backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${image})`,
                backgroundPosition: "center",
                backgroundRepeat: imageRepeat,
                backgroundSize: imagePosition,
              }),
            borderRadius:
              border === "round" ? 20 : border === "sharp" ? 0 : null,
            // justifyContent: passwordSectionVisible
            //   ? "flex-start"
            //   : "space-around",
          }}
        >
          {!passwordSectionVisible ? (
            <>
              <div className="d-flex align-items-end">
                <span
                  className={"connectTextarea"}
                  placeholder="type here"
                  style={{
                    fontSize: Number(font.size),
                    color: font.color ? font.color : "white",
                    textDecorationLine: font.isUnderlined
                      ? "underline"
                      : "none",
                    fontWeight: font.isBold ? "bold" : "normal",
                    fontStyle: font.isItalic ? "italic" : "normal",
                    fontFamily:
                      font.family === "default"
                        ? universalStyles.font
                        : font.family
                        ? font.family
                        : "sans-serif",
                    padding: 0,
                  }}
                >
                  {title}
                </span>
              </div>
              <div className="welcomForm give-form d-flex row">
                {error && (
                  <span style={{ fontSize: 12, color: "#ff0000" }}>
                    {error}
                  </span>
                )}
                <div className="formField col-md-6 ">
                  <input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    placeholder="First Name"
                  />
                </div>
                <div className="formField col-md-6">
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
                <div className="formField col-md-6">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Email Address"
                  />
                </div>
                <div className="formField col-md-6">
                  <input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="formField col-md-6">
                  <input
                    value={prayer}
                    onChange={(e) => setPrayer(e.target.value)}
                    type="text"
                    placeholder="Prayer Request"
                  />
                </div>
              </div>
              <div
                className="d-flex row align-items-center mt-2"
                style={{
                  // justifyContent: height == 8 ? "center" : "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <div className="d-flex col-md-6 flex-column">
                  <span style={{ color: "#fff", fontSize: 14 }}>
                    Is this your first time?
                  </span>
                  <select
                    value={yesNo}
                    onChange={(e) => setYesNo(e.target.value)}
                    className="watchSelect"
                  >
                    {["No", "Yes"].map((item, i) => {
                      return (
                        <option
                          key={i}
                          style={{ backgroundColor: "#FFF", color: "black" }}
                          value={item}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="submit-btn-give col-md-6">
                  {prayerRequestLoader ? (
                    <Circles
                      height="40"
                      width="40"
                      color="#fff"
                      ariaLabel="circles-loading"
                      wrapperClass="submit-btn-give"
                      visible={true}
                    />
                  ) : (
                    <input
                      onClick={(e) => {
                        e.preventDefault();
                        userRegister({
                          prayer,
                          yesNo,
                          email: email.trim(),
                          firstName: firstName.trim(),
                          lastName: lastName.trim(),
                          phone,
                        });
                      }}
                      style={{
                        background: getGradient(btnBg),
                        color: txtColor,
                        fontSize: Number(buttonFont.size),
                        fontFamily:
                          buttonFont.family === "default"
                            ? universalStyles.font
                            : buttonFont.family
                            ? buttonFont.family
                            : "sans-serif",
                        fontStyle: buttonFont.isItalic ? "italic" : "normal",
                        textDecorationLine: buttonFont.isUnderlined
                          ? "underline"
                          : "none",
                        fontWeight: buttonFont.isBold ? "bold" : "normal",
                        border: "unset",
                      }}
                      type="submit"
                      value={btnTxt ? btnTxt : "Send"}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex flex-column">
                <span
                  className={"connectTextarea"}
                  style={{
                    fontSize: 28,
                    color: "white",
                    padding: 0,
                  }}
                >
                  Set Password
                </span>
                <span
                  style={{
                    fontSize: 18,
                    color: "white",
                    padding: 0,
                    marginTop: 10,
                  }}
                >
                  Creating a password will give you access to all of the
                  features of Redefine Church’s online campus
                </span>
                <div className="d-flex justify-content-between align-items-center mt-3 give-form row">
                  <div className="formField col-md-6 ">
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="formField col-md-6">
                    <input
                      value={confirmPassword}
                      onChange={(e) => setConfrirmPassword(e.target.value)}
                      type="password"
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>
                {error && (
                  <span
                    style={{ fontSize: 12, color: "#ff0000", marginTop: 10 }}
                  >
                    {error}
                  </span>
                )}
                <div
                  className="d-flex align-items-center mt-2 align-self-start"
                  style={{ cursor: "pointer" }}
                  onClick={() => showModal(true)}
                >
                  <span
                    style={{
                      fontSize: 18,
                      color: "#fff",
                      textDecorationLine: "underline",
                      textDecorationColor: "#fff",
                      marginRight: 2,
                    }}
                  >
                    Password Hint
                  </span>
                  <Info color="white" size={16} />
                </div>
                <div className="d-flex row justify-content-between my-4">
                  <div className="col-md-6">
                    {prayerRequestLoader ? (
                      <Circles
                        height="40"
                        width="40"
                        color="#fff"
                        ariaLabel="circles-loading"
                        wrapperClass="submit-btn-give"
                        visible={true}
                      />
                    ) : (
                      <input
                        onClick={(e) => {
                          e.preventDefault();
                          handlePassword({
                            password,
                            confirmPassword,
                            email,
                            firstName,
                            lastName,
                            yesNo,
                            phone,
                          });
                        }}
                        style={{
                          background: getGradient(btnBg),
                          color: "#fff",
                          fontSize: 18,
                          border: "unset",
                        }}
                        type="submit"
                        value={"Save Password"}
                      />
                    )}
                  </div>
                  <div
                    className="d-flex col-md-6 align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      const token = localStorage.getItem("api_token");
                      if (token) {
                        const user = {
                          id: userId,
                          api_token: token,
                          email: email,
                          first_time: yesNo === "Yes" ? "1" : "0",
                          name: firstName + " " + lastName,
                          username: firstName + " " + lastName,
                          prayer_request: prayer,
                          withoutPassword: true,
                        };
                        const user_profile = {
                          user_id: userId,
                          first_name: firstName,
                          last_name: lastName,
                          phone: phone,
                        };
                        dispatch(loginSuccess(user));
                        dispatch(setUserProfile(user_profile));
                        localStorage.setItem("accessToken", token);
                        setTimeout(() => {
                          window.location.reload();
                        }, 500);
                      }
                    }}
                  >
                    <span
                      style={{
                        fontSize: 18,
                        color: "#fff",
                        textDecorationLine: "underline",
                        textDecorationColor: "#fff",
                        marginRight: 2,
                      }}
                    >
                      Don't want to create password
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    },
    []
  );

  const LogInView = useCallback(
    ({
      image,
      isDefault,
      mainColor,
      color,
      default_widget_colors,
      imagePosition,
      imageRepeat,
      border,
      universalStyles,
      btnBg,
      buttonFont,
      txtColor,
      btnTxt,
      prayer,
      sendPrayerRequest,
      prayerRequestLoader,
      user,
      passwordSectionVisible,
      password,
      confirmPassword,
      error,
      createPassword,
    }) => {
      return (
        <a
          style={{ textDecoration: "none" }}
          href={itemStyles?.widget_link}
          target="_blank"
        >
          {!passwordSectionVisible ? (
            <div
              className="bluegradientmain"
              style={{
                ...((image == "0" ||
                  image == null ||
                  image == false ||
                  image == "") && {
                  background: `${
                    isDefault && default_widget_colors.connect
                      ? default_widget_colors.connect.is_default === "0"
                        ? default_widget_colors.connect.color
                        : default_widget_colors.connect.is_default === "1"
                        ? mainColor
                        : getGradient(color)
                      : getGradient(color)
                  }`,
                }),
                ...(image !== "0" &&
                  image !== null &&
                  image !== false &&
                  image !== "" && {
                    backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${image})`,
                    backgroundPosition: "center",
                    backgroundRepeat: imageRepeat,
                    backgroundSize: imagePosition,
                  }),
                borderRadius:
                  border === "round" ? 20 : border === "sharp" ? 0 : null,
                justifyContent: "space-between",
              }}
            >
              <div className="d-flex row justify-content-between">
                <div className="col-md-6">
                  <h6 style={{ color: "#fff" }}>Welcome back</h6>
                  <h4 style={{ color: "#fff", fontWeight: "600" }}>
                    {user?.username}
                  </h4>
                </div>
                <div className="d-flex justify-content-end col-md-6 ">
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.clear();
                      window.location.reload();
                    }}
                    style={{ color: "#fff", cursor: "pointer" }}
                  >
                    Not You?
                  </span>
                </div>
              </div>

              <div className="welcomForm give-form d-flex row">
                <div className="formField col-md-8 ">
                  <span style={{ color: "#fff", fontWeight: "600" }}>
                    Prayer Request
                  </span>
                  <textarea
                    onClick={(e) => e.preventDefault()}
                    value={prayer}
                    onChange={(e) => setPrayer(e.target.value)}
                    className="logout-textarea"
                    type="text"
                    placeholder="Please enter your prayer request"
                  />
                </div>
              </div>
              <div className="d-flex row align-items-end justify-content-between">
                <div className="submit-btn-give col-md-6">
                  {prayerRequestLoader ? (
                    <Circles
                      height="40"
                      width="40"
                      color="#fff"
                      ariaLabel="circles-loading"
                      wrapperClass="submit-btn-give"
                      visible={true}
                    />
                  ) : (
                    <input
                      onClick={(e) => {
                        e.preventDefault();
                        sendPrayerRequest(prayer);
                      }}
                      style={{
                        background: getGradient(btnBg),
                        color: txtColor,
                        fontSize: Number(buttonFont.size),
                        fontFamily:
                          buttonFont.family === "default"
                            ? universalStyles.font
                            : buttonFont.family
                            ? buttonFont.family
                            : "sans-serif",
                        fontStyle: buttonFont.isItalic ? "italic" : "normal",
                        textDecorationLine: buttonFont.isUnderlined
                          ? "underline"
                          : "none",
                        fontWeight: buttonFont.isBold ? "bold" : "normal",
                        border: "unset",
                      }}
                      type="submit"
                      value={btnTxt ? btnTxt : "Send"}
                    />
                  )}
                </div>
                {user && user?.withoutPassword && (
                  <div
                    className="col-md-6 d-flex justify-content-end"
                    onClick={(e) => {
                      e.preventDefault();
                      setPasswordSectionVisible(true);
                    }}
                  >
                    <span
                      style={{
                        fontSize: 18,
                        color: "#fff",
                        textDecorationLine: "underline",
                        textDecorationColor: "#fff",
                      }}
                    >
                      Create Password
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="bluegradientmain d-flex flex-column"
              style={{
                ...((image == "0" ||
                  image == null ||
                  image == false ||
                  image == "") && {
                  background: `${
                    isDefault && default_widget_colors.connect
                      ? default_widget_colors.connect.is_default === "0"
                        ? default_widget_colors.connect.color
                        : default_widget_colors.connect.is_default === "1"
                        ? mainColor
                        : getGradient(color)
                      : getGradient(color)
                  }`,
                }),
                ...(image !== "0" &&
                  image !== null &&
                  image !== false &&
                  image !== "" && {
                    backgroundImage: `url(${process.env.REACT_APP_BASE_URL}${image})`,
                    backgroundPosition: "center",
                    backgroundRepeat: imageRepeat,
                    backgroundSize: imagePosition,
                  }),
                borderRadius:
                  border === "round" ? 20 : border === "sharp" ? 0 : null,
                justifyContent: "space-between",
              }}
            >
              <span
                className={"connectTextarea"}
                style={{
                  fontSize: 28,
                  color: "white",
                  padding: 0,
                }}
              >
                Set Password
              </span>
              <span
                style={{
                  fontSize: 18,
                  color: "white",
                  padding: 0,
                  marginTop: 10,
                }}
              >
                Creating a password will give you access to all of the features
                of Redefine Church’s online campus
              </span>
              <div
                onClick={(e) => e.preventDefault()}
                className="d-flex justify-content-between align-items-center mt-3 give-form row"
              >
                <div className="formField col-md-6 ">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                  />
                </div>
                <div className="formField col-md-6">
                  <input
                    value={confirmPassword}
                    onChange={(e) => setConfrirmPassword(e.target.value)}
                    type="password"
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                  />
                </div>
              </div>
              {error && (
                <span style={{ fontSize: 12, color: "#ff0000", marginTop: 10 }}>
                  {error}
                </span>
              )}
              <div
                className="d-flex align-items-center mt-2 align-self-start"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  showModal(true);
                }}
              >
                <span
                  style={{
                    fontSize: 18,
                    color: "#fff",
                    textDecorationLine: "underline",
                    textDecorationColor: "#fff",
                    marginRight: 2,
                  }}
                >
                  Password Hint
                </span>
                <Info color="white" size={16} />
              </div>
              <div
                onClick={(e) => e.preventDefault()}
                className="d-flex row justify-content-between my-4"
              >
                <div className="col-md-6">
                  {prayerRequestLoader ? (
                    <Circles
                      height="40"
                      width="40"
                      color="#fff"
                      ariaLabel="circles-loading"
                      wrapperClass="submit-btn-give"
                      visible={true}
                    />
                  ) : (
                    <input
                      onClick={(e) => {
                        e.preventDefault();
                        createPassword(password, confirmPassword, user);
                      }}
                      style={{
                        background: getGradient(btnBg),
                        color: "#fff",
                        fontSize: 18,
                        border: "unset",
                      }}
                      type="submit"
                      value={"Save Password"}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </a>
      );
    },
    []
  );

  return (
    <>
      {user?.api_token ? (
        <LogInView
          image={image}
          isDefault={isDefault}
          color={color}
          mainColor={mainColor}
          default_widget_colors={default_widget_colors}
          imagePosition={imagePosition}
          imageRepeat={imageRepeat}
          border={border}
          universalStyles={universalStyles}
          title={title}
          btnBg={btnBg}
          btnTxt={btnTxt}
          buttonFont={buttonFont}
          txtColor={txtColor}
          prayer={prayer}
          sendPrayerRequest={sendPrayerRequest}
          prayerRequestLoader={prayerRequestLoader}
          user={user}
          passwordSectionVisible={passwordSectionVisible}
          password={password}
          confirmPassword={confirmPassword}
          error={error}
          createPassword={createPassword}
        />
      ) : (
        <LogOutView
          email={email}
          firstName={firstName}
          lastName={lastName}
          phone={phone}
          prayer={prayer}
          image={image}
          isDefault={isDefault}
          color={color}
          mainColor={mainColor}
          default_widget_colors={default_widget_colors}
          imagePosition={imagePosition}
          imageRepeat={imageRepeat}
          border={border}
          font={font}
          universalStyles={universalStyles}
          title={title}
          yesNo={yesNo}
          btnBg={btnBg}
          btnTxt={btnTxt}
          buttonFont={buttonFont}
          txtColor={txtColor}
          userRegister={userRegister}
          prayerRequestLoader={prayerRequestLoader}
          error={error}
          confirmPassword={confirmPassword}
          password={password}
          passwordSectionVisible={passwordSectionVisible}
          handlePassword={handlePassword}
          showModal={showModal}
          userId={userId}
        />
      )}
      {isModalVisible && (
        <PasswordHintModal isModalVisible={isModalVisible} onHide={showModal} />
      )}
    </>
  );
};

export default ConnectWidget;
