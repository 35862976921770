import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useDispatch } from "react-redux";
import { setTeamId } from "../../store/slices/bodyStyling";
import Assets from "../../assets/images";

const TeamCarousel = ({ data }) => {
  const dispatch = useDispatch();

  return (
    <Carousel
      autoPlay={false}
      infiniteLoop={false}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      onChange={(e) => {
        const teamId = data[e].id;
        dispatch(setTeamId(teamId));
      }}
    >
      {data.length > 0 ? (
        data.map((item) => {
          return (
            <div className="d-flex" key={item.id}>
              <img
                style={{
                  height: 500,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                src={`${process.env.REACT_APP_BASE_URL}${item?.team_banner}`}
              />
              <div className="team-blur-footer d-flex flex-column gap-2">
                <h4 style={{ color: "#fff" }}>{item.title}</h4>
                <p style={{ color: "#fff" }}>{item.description}</p>
              </div>
            </div>
          );
        })
      ) : (
        <div className="d-flex">
          <img
            style={{
              height: 500,
              objectFit: "cover",
              objectPosition: "center",
            }}
            src={Assets.Event1}
          />
          <div
            className="team-blur-footer d-flex flex-column align-items-center justify-content-center gap-2"
            style={{
              top:0
            }}
          >
            <h4 style={{ color: "#fff" }}>No Teams Found</h4>
          </div>
        </div>
      )}
    </Carousel>
  );
};

export default TeamCarousel;
