import React, { useEffect, useState, useCallback } from "react";
import "../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setEventNoteText,
  setSelectedEventDeatails,
  setSermonsData,
} from "../../store/slices/watchSlice";
import XHR from "../../utills/XHR";
import { debounce } from "lodash";

const Notes = ({ borderRadius }) => {
  const currentSelectedEventData = useSelector(
    (state) => state.watch.currentSelectedEventData
  );
  const user = useSelector((state) => state.login.userData);
  const noteText = useSelector((state) => state.watch.noteText);
  const data = useSelector((state) => state.watch.sermonsData);
  const selectedEvent = useSelector(
    (state) => state.watch.selectedEventDetails
  );
  const seriesId = useSelector((state) => state.watch.seriesId);
  const eventId = useSelector((state) => state.watch.eventId);
  const liveEvent = useSelector((state) => state.watch.liveEvent);

  const dispatch = useDispatch();

  const [note, setNote] = useState("");

  useEffect(() => {
    setNote(noteText);
  }, [noteText]);

  const setNoteApi = (e) => {
    setNote(e);
    debounceApiRequest(e, eventId);
  };

  const debounceApiRequest = useCallback(
    debounce(async (text, event_id) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.api_token}`,
        },
        method: "POST",
        data: {
          event_id: event_id,
          message: text,
          user_id: user.id,
        },
      };
      try {
        XHR(`${process.env.REACT_APP_BASE_URL}/api/v1/update-notes`, options)
          .then((response) => {
            console.log("RESP Notes>", response?.data);
            if (!data?.series) return;
            const tempData = JSON.parse(JSON.stringify(data.series));
            const tempSeries = [...tempData];
            const seriesIndex = tempSeries.findIndex(
              (series) => series.series_id == selectedEvent?.series_id
            );
            if (seriesIndex == -1) {
              return;
            }
            const eventIndex = tempSeries[seriesIndex].events.findIndex(
              (event) => event.event_id == event_id
            );
            if (eventIndex == -1) {
              return;
            }

            if (tempSeries?.[seriesIndex]?.events?.[eventIndex]?.notes?.[0]) {
              const notes = [
                {
                  ...tempSeries[seriesIndex].events[eventIndex].notes[0],
                  note_text: text,
                },
              ];
              tempSeries[seriesIndex].events[eventIndex].notes = notes;
            } else {
              tempSeries[seriesIndex].events[eventIndex].notes = [
                { note_text: text },
              ];
            }
            const updatedData = {
              selectedSeries: data?.selectedSeries,
              series: tempSeries,
            };
            console.log("UPDATED>", updatedData);
            dispatch(setSermonsData(updatedData));
          })
          .catch((error) => {
            console.log("ERRRR>", error);
          });
      } catch (error) {
        console.error("API Error:", error);
      }
    }, 1000),
    [data, currentSelectedEventData, selectedEvent]
  );

  return (
    <div
      className={`chatBox`}
      style={{
        borderRadius: borderRadius,
        padding: 25,
        width: "65%",
        height: "100%",
      }}
    >
      <textarea
        className="chat-Input"
        style={{ minHeight: "80%" }}
        placeholder="Take notes..."
        value={note}
        onChange={(e) => setNoteApi(e.target.value)}
      />
      <div
        onClick={() => {
          const series = data?.series?.find(
            (series) => series.series_id == seriesId
          );
          const event = series.events.find(
            (event) => event.event_id == eventId
          );

          const tempData = {
            playCheck: true,
            status:
              liveEvent &&
              liveEvent?.stream_session_id === event?.stream_session_id
                ? 1
                : 0,
            stream_session_id: event?.stream_session_id,
            series_id: seriesId,
            event_id: eventId,
            chat: event?.chat,
            notes: event?.notes,
          };
          dispatch(setSelectedEventDeatails(tempData));

          if (event && event?.notes?.[0]?.note_text) {
            dispatch(setEventNoteText(event.notes[0].note_text));
          } else {
            dispatch(setEventNoteText(""));
          }
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        className="align-items-center justify-content-center"
        style={{
          backgroundColor: "#3478ff",
          alignSelf: "flex-end",
          borderRadius: 6,
          cursor: "pointer",
        }}
      >
        <p
          style={{
            margin: 0,
            color: "#ffffff",
            paddingInline: 20,
            paddingBlock: 10,
          }}
        >
          Watch Now
        </p>
      </div>
    </div>
  );
};

export default Notes;
