import React, { useEffect, useState, useRef } from "react";
import "../home/styles.css";
import { useSelector } from "react-redux";
import { Player, ControlBar, BigPlayButton } from "video-react";
// import "video-react/dist/video-react.css";
import liveGif from "../../assets/live.gif";
import HLSSource from "../Widgets/videoWidget/HLSSource";

const PlayerWidget = ({ borderRadius }) => {
  const playerRef = useRef(null);
  const [streamId, setStreamId] = useState(null);
  const [border, setBorder] = useState("");

  const selectedEvent = useSelector(
    (state) => state.watch.selectedEventDetails
  );
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );

  useEffect(() => {
    if (selectedEvent?.stream_session_id && selectedEvent?.playCheck) {
      setStreamId(selectedEvent?.stream_session_id);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (universalStyles.corners) {
      setBorder(universalStyles.corners);
    }

    const handleVisibilityChange = () => {
      const player = playerRef.current;
      if (!player) return;

      if (document.hidden) {
        player.pause();
      } else {
        const promise = player.play();
        if (promise !== undefined) {
          promise.catch((error) => {
            console.log("Play was prevented: ", error);
          });
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <div
      className="d-flex justify-content-center"
      style={{
        borderRadius: borderRadius,
        width: "65%",
        height: "100%",
        position: "relative",
      }}
    >
      <Player ref={playerRef} autoPlay muted>
        <HLSSource
          isVideoChild
          src={`https://live.redefine.church/live/${streamId}/index.m3u8`}
        />
        {selectedEvent?.status == 1 && (
          <img className="live-gif" style={{ right: 10 }} src={liveGif} />
        )}
        <ControlBar className={border === "round" ? "bottom-border" : ""} autoHide={true} />
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};

export default PlayerWidget;
