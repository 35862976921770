import React, { useContext, useState, useCallback, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
//Context Api
import { DarkModeContext } from "../context/DarkModeContext";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Header from "../components/header";
import Footer from "../components/footer";
import TeamCarousel from "../components/TeamCarousel";
import { PhotoProvider } from "react-photo-view";

const ProtectedRoute = ({ component: Component }) => {
  const { darkMode, handleLightMode, handleDarkMode, borderCorners } =
    useContext(DarkModeContext);
  const token = localStorage.getItem("accessToken");
  const isLoading = useSelector((state) => state.bodyStyling.submitLoader);
  const teamsData = useSelector((state) => state.bodyStyling.teamsData);

  // console.log("TEAMS>",teamsData);
  

  const { pathname } = useLocation();

  if (token === "undefined" || token === null) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Header darkMode={darkMode} />
      <div
        className={`mainWrapper ${darkMode ? "darkMode" : "lightMode"} `}
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        <Loader loading={isLoading} />
        {/* {pathname === "/giving" && (
          <div className="d-flex align-items-end justify-content-center give-banner">
            <h1 style={{ color: "#fff" }}>Redefine Give</h1>
          </div>
        )} */}
        {/* {pathname === "/group" && (
          <div className="d-flex align-items-end justify-content-center give-banner group-banner">
            <h1 style={{ color: "#fff" }}>Redefine Groups</h1>
          </div>
        )} */}
        {pathname === "/team" && (
          <div
            className="d-flex align-items-end justify-content-center"
            style={{ marginTop: 50, width: "100%" }}
          >
            <TeamCarousel
              data={
                teamsData?.teams && teamsData?.teams?.length > 0
                  ? teamsData?.teams
                  : []
              }
            />
          </div>
        )}
        <PhotoProvider>
          <Component darkMode={darkMode} />
        </PhotoProvider>
      </div>
      <Footer darkMode={darkMode} />
    </div>
  );
};

export default ProtectedRoute;
