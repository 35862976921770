import React, { useState, useEffect, useRef } from "react";
import { Circles } from "react-loader-spinner";
import {
    Trash2,
    Pencil,
    ArrowUp,
    ArrowDown,
    CalendarDays,
    X,
    CircleAlert,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import Assets from "../../../assets/images";
import { getGradient } from "../../../utills/getGradient";
import {
    setDonateDropDown,
    setGivingData,
} from "../../../store/slices/givingSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { duration } from "moment";
import { Modal } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "../../../components/CardForm/CardForm";
import { getRequest, postRequest } from "../../../utills/requests";
import toast from "react-hot-toast";
import { submitRequest } from "../../../store/slices/bodyStyling";
import { v4 as uuidv4 } from "uuid";

const stripePromise = loadStripe(
    "pk_test_51I9yeoKMoDqLsqS5dvTPHvSkem5ST2ytATHH9Bw7J94pAizNveV5t0ZygqA4G81n4SZqmsYUZE5i84FlRZzLB7fn00i1RNKiPs"
);

const GivingDonateWidget = ({ itemStyles, width, height, widgetId }) => {
    const [isChecked, setChecked] = useState(false);
    const editingMode = useSelector((state) => state.bodyStyling.isEdit);
    const user = useSelector((state) => state.login.userData);
    const givingData = useSelector((state) => state.giving.givingData);
    const [defaultCard, setDefaultCard] = useState(false);
    const [giving, setGiving] = useState(false);
    const [donateLoader, setDonateLoader] = useState(false);
    const [achName, setAchName] = useState("");
    const [achRouteNumber, setRouteNumber] = useState("");
    const [achAccountNumber, setAccountNumber] = useState("");
    const [achLoader, setAchLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [editAmount, setEditAmount] = useState(0);

    useEffect(() => {
        if (givingData?.default_card) {
            setDefaultCard(givingData.default_card);
            setCustomer({
                card_id: givingData.default_card?.id,
                customer: givingData?.default_card?.customer,
            });
        }
        if (givingData?.giving) {
            setGiving(givingData?.giving);
        }
    }, [givingData]);

    const [border, setBorder] = useState("");
    const universalStyles = useSelector(
        (state) => state.bodyStyling.universalStyles
    );
    const { default_widget_colors, mainColor } = universalStyles;
    const [defaultCorners, setDefaultCorners] = useState("true");
    const dropDown = useSelector((state) => state.giving.donateDropDown);
    const [date, setDate] = useState(new Date());
    const dispatch = useDispatch();

    const [cancelModal, showCancelModal] = useState(false);
    const [editModal, showEditModal] = useState(false);
    const [achModal, showACHModal] = useState(false);
    const [cardModal, showCardModal] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState("");
    const [cancelLoader, setCancelLoader] = useState(false);

    const [customer, setCustomer] = useState({ card_id: "", customer: "" });
    const [editCustomer, setEditCustomer] = useState({
        card_id: "",
        customer: "",
    });
    const [amount, setAmount] = useState("");

    const frequencyOptions = [
        { id: 1, name: "Weekly" },
        { id: 2, name: "Bi-Weekly" },
        { id: 3, name: "Monthly" },
    ];

    const cards = [
        { id: 1, brand: "VISA", last4: 1245 },
        { id: 2, brand: "MASTERCARD", last4: 2345 },
        { id: 3, brand: "SOFI BANK, NATIONAL", last4: 1234 },
        { id: 4, brand: "VISA", last4: 9999 },
        { id: 5, brand: "VISA", last4: 8754 },
    ];

    const [frequencyId, setFrequencyId] = useState(1);
    const [editFrequencyId, setEditFrequencyId] = useState(1);

    const mapping = {
        month: "Monthly",
        week: "Weekly",
        "every 2 weeks": "Biweekly",
    };

    const mappingIds = { month: 3, "every 2 weeks": 2, week: 1 };

    useEffect(() => {
        if (universalStyles.corners && defaultCorners == "true") {
            setBorder(universalStyles.corners);
        }
        if (universalStyles.font) {
            font.isDefaultFont == 1 &&
                setFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
            buttonFont.isDefaultFont == 1 &&
                setButtonFont((prevFont) => ({
                    ...prevFont,
                    family: universalStyles.font,
                }));
        }
    }, [universalStyles.corners, universalStyles.font]);

    useEffect(() => {
        if (itemStyles) {
            // setColor(itemStyles.background_color);
            if (itemStyles.background_color) {
                try {
                    const bgstyle = JSON.parse(itemStyles.background_color);
                    setColor(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.background_color;
                    setColor(bgstyle);
                }
            }
            setBtnTxt(itemStyles.button_text);
            // setBtnBg(itemStyles.button_background_color);
            if (itemStyles.button_background_color) {
                try {
                    const bgstyle = JSON.parse(
                        itemStyles.button_background_color
                    );
                    setBtnBg(bgstyle);
                } catch (error) {
                    const bgstyle = itemStyles.button_background_color;
                    setBtnBg(bgstyle);
                }
            }
            setTxtColor(itemStyles.button_text_color);
            if (itemStyles.title_font_style) {
                try {
                    const fontstyle = JSON.parse(itemStyles.title_font_style);
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                } catch (error) {
                    const fontstyle = itemStyles.title_font_style;
                    setFont({
                        ...fontstyle,
                        color: itemStyles.title_color,
                        family: itemStyles.title_font,
                        size: itemStyles.title_font_size,
                    });
                }
            }
            if (itemStyles.button_font_style) {
                try {
                    const buttonStyle = JSON.parse(
                        itemStyles.button_font_style
                    );
                    setButtonFont({
                        ...buttonStyle,
                        color: itemStyles.button_text_color,
                        family: itemStyles.button_font,
                        size: itemStyles.button_font_size,
                    });
                    setDefaultButton(buttonStyle.isDefaultButton);
                } catch (error) {
                    const buttonStyle = itemStyles.button_font_style;
                    setButtonFont({
                        ...buttonStyle,
                        color: itemStyles.button_text_color,
                        family: itemStyles.button_font,
                        size: itemStyles.button_font_size,
                    });
                    setDefaultButton(buttonStyle.isDefaultButton);
                    console.error(
                        "Error parsing heading title font style:",
                        error
                    );
                }
            }

            setLink(itemStyles.widget_link);
            setBtnLink(itemStyles.button_link);
            setBorder(itemStyles.border);
            setDefaultCorners(itemStyles.defaultCorners);
            setDefault(itemStyles.is_default);
        }
    }, [itemStyles]);

    const handleToggleChange = () => {
        setChecked((prev) => !prev);
    };

    const [isDefaultButton, setDefaultButton] = useState(0);
    const [buttonFont, setButtonFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "gilroymedium",
        size: 18,
        isDefaultFont: 1,
    });

    const [isDefault, setDefault] = useState(0);
    const [isEditText, setEditText] = useState(false);
    const [color, setColor] = useState("#f8acac");
    const [font, setFont] = useState({
        color: "#ffffff",
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        family: "frontage",
        size: 22,
        isDefaultFont: 1,
    });
    const [btnBg, setBtnBg] = useState("");
    const [btnTxt, setBtnTxt] = useState("");
    const [txtColor, setTxtColor] = useState("");
    const [link, setLink] = useState("");
    const [btnlink, setBtnLink] = useState("");

    const removeCard = async (card_id, customer_stripe_id) => {
        const data = {
            card_id,
            customer_stripe_id,
        };
        try {
            dispatch(submitRequest(true));
            const response = await postRequest({
                endpoint: "/removeExistingCard",
                payload: data,
            });
            toast.success("Payment method removed successfully.");
            requestGiving();
            console.log("RESP>", response.data);
        } catch (error) {
            console.log("ERR>", error);
            dispatch(submitRequest(false));
        }
    };

    const requestGiving = async () => {
        try {
            const response = await getRequest({
                endpoint: `/getGiving?user_id=${user.id}`,
            });
            console.log("RESR>>", response);
            dispatch(setGivingData(response));
        } catch (e) {
            console.log("ERR>", e);
        } finally {
            dispatch(submitRequest(false));
        }
    };

    const cancelSubscription = async () => {
        if (cancelLoader) return;
        try {
            setCancelLoader(true);
            const response = await postRequest({
                endpoint: `/cancelSubscriptions`,
                payload: { subscriptions_id: subscriptionId },
            });
            console.log("RESR>>", response);
            toast.success("Subscription cancelled successfully.");
            showCancelModal(false);
            dispatch(submitRequest(true));
            requestGiving();
        } catch (e) {
            console.log("ERR>", e);
            if (e?.response?.data?.message) {
                toast.error(e?.response?.data?.message, { duration: 2000 });
                showCancelModal(false);
            }
        } finally {
            setCancelLoader(false);
        }
    };

    const mapFrequency = { 1: "Weekly", 2: "Biweekly", 3: "Monthly" };

    // START FROM HERE
    const donateNow = async () => {
        if (amount.trim().length == 0 || amount == 0) {
            toast.error("Please enter a valid amount");
            return;
        }

        if (!customer.card_id) {
            toast.error(
                "Can't proceed this payment as there is no Card/Account available or selected!",
                { duration: 6000 }
            );
            return;
        }

        const data = {
            amount: amount,
            gift: isChecked ? "Yes" : "No",
            datepicker_date: moment(date).format("YYYY-MM-DD"),
            customer: customer.customer,
            frequency: mapFrequency[frequencyId],
            currency_code: "USD",
            item_name: "Giving",
            item_number: "Giving-" + uuidv4(),
            card_id: customer.card_id,
        };
        setDonateLoader(true);
        try {
            const response = await postRequest({
                endpoint: `/stripePostPay`,
                payload: data,
            });
            console.log("RES>", response.data);
            if (response?.data?.message) {
                toast.success(response.data.message, { duration: 2500 });
                setAmount("");
                dispatch(submitRequest(true));
                requestGiving();
                setFrequencyId(1);
            }
        } catch (error) {
            console.log("ER>", error.response);
            if (error?.response?.data?.message) {
                toast.error(error.response.data.message, { duration: 3000 });
            }
        } finally {
            setDonateLoader(false);
        }
    };

    const addNewAch = async () => {
        const data = {
            ach_customer_name: achName,
            ach_routing_number: achRouteNumber,
            ach_account_number: achAccountNumber,
        };
        setAchLoader(true);
        try {
            const response = await postRequest({
                endpoint: `/addNewAchAjax`,
                payload: data,
            });
            console.log("RESP>", response.data);
            if (response?.data?.message && response?.data?.status !== 0) {
                toast.success(response?.data?.message, { duration: 3000 });
                showACHModal(false);
                setAccountNumber("");
                setAchName("");
                setRouteNumber("");
                dispatch(submitRequest(true));
                requestGiving();
            } else {
                toast.error(response?.data?.message, { duration: 3000 });
            }
        } catch (error) {
            console.log("ERR>", error.response);
            if (error?.response?.data?.message) {
                toast.error(error.response.data.message, { duration: 3000 });
            }
        } finally {
            setAchLoader(false);
        }
    };

    const updateSubscription = async () => {
        const data = {
            sub_amount: editAmount,
            sub_frequency: mapFrequency[editFrequencyId],
            sub_customer: editCustomer.customer,
            sub_currency_code: "USD",
            subscriptions_id: subscriptionId,
            sub_card_id: editCustomer.card_id,
        };
        setUpdateLoader(true);
        try {
            const response = await postRequest({
                endpoint: `/updateSubscriptions`,
                payload: data,
            });
            if (response.data.message) {
                showEditModal(false);
                toast.success(response.data.message, { duration: 2500 });
                dispatch(submitRequest(true));
                setEditCustomer({ card_id: "", customer: "" });
                requestGiving();
            }
            console.log("Resp>", response);
        } catch (error) {
            console.log("ERR>", error?.response);
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message, {
                    duration: 5000,
                    style: {
                        maxWidth: 500,
                        wordBreak: "break-all",
                    },
                });
            }
        } finally {
            setUpdateLoader(false);
        }
    };

    return (
        <div
            className={`col-md-12 giving`}
            style={{
                background: `${
                    isDefault && default_widget_colors.giving
                        ? default_widget_colors.giving.is_default === "0"
                            ? default_widget_colors.giving.color
                            : default_widget_colors.giving.is_default === "1"
                            ? mainColor
                            : getGradient(color)
                        : getGradient(color)
                }`,
                justifyContent: height == 8 ? "space-around" : "space-between",
                borderRadius:
                    border === "round" ? 20 : border === "sharp" ? 0 : null,
                minHeight: "unset",
            }}
        >
            <div className="d-flex flex-column">
                <h2
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    style={{
                        color: font.color ? font.color : "white",
                        textDecorationLine: font.isUnderlined
                            ? "underline"
                            : "none",
                        fontWeight: font.isBold ? "bold" : "normal",
                        fontStyle: font.isItalic ? "italic" : "normal",
                        fontFamily: font.family,
                        fontSize: Number(font.size),
                        outline: isEditText ? "dotted" : "unset",
                        pointerEvents:
                            editingMode.widgtId == widgetId ? "all" : "none",
                        alignSelf: "flex-start",
                    }}
                    className="maintitle"
                >
                    Giving
                </h2>
                <div
                    className="d-flex col-md-12 justify-content-between align-items-center my-3"
                    style={{ flexWrap: "wrap", rowGap: 10 }}
                >
                    <div
                        style={{
                            border: "1px solid #fff",
                            borderWidth: 0,
                            borderBottomWidth: 1,
                        }}
                        className="d-flex col-md-6 align-items-center"
                    >
                        <span style={{ color: "#fff" }}>$</span>
                        <input
                            className="givingInput"
                            type="number"
                            placeholder="Amount"
                            value={amount}
                            style={{
                                width: "90%",
                                fontSize: 16,
                                paddingBottom: 0,
                            }}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Check for min and maxLength
                                if (value.length <= 9 && value >= 0) {
                                    setAmount(value);
                                }
                            }}
                        />
                    </div>
                    <div
                        className="d-flex col-md-6 align-items-center justify-content-end"
                        style={{ columnGap: 8 }}
                    >
                        <label
                            style={{ marginBottom: 0 }}
                            className="toggle-switch"
                        >
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleToggleChange}
                            />
                            <span className="toggle-slider"></span>
                        </label>
                        <h4
                            style={{
                                color: "#ffffff",
                                fontSize: 16,
                                margin: 0,
                                fontWeight: "500",
                            }}
                        >
                            Reoccuring Gift
                        </h4>
                    </div>
                </div>
                {!dropDown && !isChecked && (
                    <div className="mt-3">
                        <div className="d-flex mt-2 col-md-12 align-items-center">
                            <p
                                style={{
                                    margin: 0,
                                    color: "#ffffff",
                                    lineHeight: "10px",
                                }}
                            >
                                **** **** **** {defaultCard?.last4}
                            </p>
                        </div>
                    </div>
                )}

                {isChecked && (
                    <div className="d-flex col-md-12 mt-2 justify-content-between align-items-center">
                        <div className="d-flex col-md-4 inputCont align-items-center">
                            <DatePicker
                                dateFormat={"yyyy-MM-dd"}
                                minDate={new Date()}
                                editable={false}
                                selected={date}
                                closeOnScroll
                                onChange={(date) => setDate(date)}
                            />
                            <CalendarDays
                                fill="transparent"
                                color="#fff"
                                size={20}
                            />
                        </div>
                        <div
                            className="d-flex col-md-8 align-items-center justify-content-end"
                            style={{ flexWrap: "wrap", gap: 4 }}
                        >
                            {frequencyOptions.map((item) => {
                                return (
                                    <div
                                        key={item.id}
                                        onClick={() => setFrequencyId(item.id)}
                                        className="d-flex align-items-center justifycontent-end"
                                        style={{
                                            cursor: "pointer",
                                            backgroundColor:
                                                frequencyId === item.id
                                                    ? "#000000"
                                                    : "#ffffff",
                                            borderRadius: 5,
                                            paddingBlock: 6,
                                            paddingInline: 14,
                                        }}
                                    >
                                        <h6
                                            style={{
                                                margin: 0,
                                                fontSize: 12,
                                                color: "#fb6262",
                                            }}
                                        >
                                            {item.name}
                                        </h6>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {dropDown && (
                    <>
                        <div className="my-3">
                            <h4
                                style={{
                                    color: "#ffffff",
                                    fontSize: 14,
                                    margin: 0,
                                    fontWeight: "500",
                                }}
                            >
                                Reoccuring Gift
                            </h4>
                            <div className="d-flex flex-column reoccuringGifts mt-2 ">
                                {giving?.subscription &&
                                    giving.subscription.length > 0 &&
                                    giving.subscription.map((item) => {
                                        return (
                                            <div
                                                key={
                                                    item.stripe_subscription_id
                                                }
                                                className="d-flex align-items-center justify-content-between subscriptionItem "
                                            >
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                        fontSize: 14,
                                                        color: "#f06969",
                                                        lineHeight: "10px",
                                                    }}
                                                >
                                                    ${item.plan_amount} -{" "}
                                                    {
                                                        mapping[
                                                            item.plan_interval
                                                        ]
                                                    }
                                                </p>
                                                <div
                                                    className="d-flex  align-items-center"
                                                    style={{ columnGap: 10 }}
                                                >
                                                    {item.status !==
                                                    "active" ? (
                                                        <p
                                                            style={{
                                                                margin: 0,
                                                                fontSize: 14,
                                                                color: "black",
                                                                lineHeight:
                                                                    "0px",
                                                            }}
                                                        >
                                                            Starting on{" "}
                                                            {moment(
                                                                item.plan_period_end
                                                            ).format(
                                                                "DD MMM, YYYY"
                                                            )}
                                                        </p>
                                                    ) : (
                                                        <Pencil
                                                            onClick={() => {
                                                                showEditModal(
                                                                    true
                                                                );
                                                                setEditAmount(
                                                                    item.plan_amount
                                                                );
                                                                setEditFrequencyId(
                                                                    mappingIds[
                                                                        item
                                                                            .plan_interval
                                                                    ]
                                                                );
                                                                setSubscriptionId(
                                                                    item.stripe_subscription_id
                                                                );
                                                                if (
                                                                    giving?.cards &&
                                                                    giving.cards
                                                                        .length >
                                                                        0
                                                                ) {
                                                                    setEditCustomer(
                                                                        {
                                                                            card_id:
                                                                                giving
                                                                                    .cards[0]
                                                                                    .id,
                                                                            customer:
                                                                                giving
                                                                                    .cards[0]
                                                                                    .customer,
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            color="#000000"
                                                            size={14}
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    )}
                                                    <Trash2
                                                        onClick={() => {
                                                            showCancelModal(
                                                                true
                                                            );
                                                            setSubscriptionId(
                                                                item.stripe_subscription_id
                                                            );
                                                        }}
                                                        color="red"
                                                        size={14}
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div>
                            <h4
                                style={{
                                    color: "#ffffff",
                                    fontSize: 14,
                                    margin: 0,
                                    fontWeight: "500",
                                }}
                            >
                                Payment methods
                            </h4>
                            <div className="parent mt-2 align-items-center paymentMethods">
                                {giving?.cards &&
                                    giving.cards.length > 0 &&
                                    giving.cards.map((item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                onClick={() => {
                                                    setCustomer({
                                                        card_id: item.id,
                                                        customer: item.customer,
                                                    });
                                                    // setCardId(item.id);
                                                }}
                                                className="child d-flex flex-column justify-content-between"
                                                style={{
                                                    cursor: "pointer",
                                                    padding: 10,
                                                    backgroundColor: "#f46363",
                                                    borderRadius: 12,
                                                    rowGap: 6,
                                                    minHeight: 86,
                                                    position: "relative",
                                                    border:
                                                        customer.card_id ===
                                                        item.id
                                                            ? "1px solid #fff"
                                                            : "unset",
                                                    boxShadow:
                                                        customer.card_id ===
                                                        item.id
                                                            ? "rgb(139 139 139) 0px 1px 5px 0px"
                                                            : "unset",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: "#ffffff",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {item.brand}
                                                </p>
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: "#ffffff",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    **** **** **** {item.last4}
                                                </p>
                                                <X
                                                    onClick={(e) => {
                                                        removeCard(
                                                            item.id,
                                                            item.customer
                                                        );
                                                        e.stopPropagation();
                                                    }}
                                                    color="#fff"
                                                    fill="#fff"
                                                    size={16}
                                                    strokeWidth={4}
                                                    style={{
                                                        position: "absolute",
                                                        top: 2,
                                                        right: 4,
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                {giving?.ach &&
                                    giving.ach.length > 0 &&
                                    giving.ach.map((item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                onClick={() => {
                                                    setCustomer({
                                                        card_id: item.id,
                                                        customer: item.customer,
                                                    });
                                                    // setCardId(item.id);
                                                }}
                                                className="child d-flex flex-column justify-content-between"
                                                style={{
                                                    cursor: "pointer",
                                                    padding: 10,
                                                    backgroundColor: "#f46363",
                                                    borderRadius: 12,
                                                    rowGap: 6,
                                                    minHeight: 86,
                                                    position: "relative",
                                                    border:
                                                        customer.card_id ===
                                                        item.id
                                                            ? "1px solid #fff"
                                                            : "unset",
                                                    boxShadow:
                                                        customer.card_id ===
                                                        item.id
                                                            ? "rgb(139 139 139) 0px 1px 5px 0px"
                                                            : "unset",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: "#ffffff",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {item.bank_name}
                                                </p>
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: "#ffffff",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    **** **** **** {item.last4}
                                                </p>
                                                <X
                                                    onClick={(e) => {
                                                        removeCard(
                                                            item.id,
                                                            item.customer
                                                        );
                                                        e.stopPropagation();
                                                    }}
                                                    color="#fff"
                                                    fill="#fff"
                                                    size={16}
                                                    strokeWidth={4}
                                                    style={{
                                                        position: "absolute",
                                                        top: 2,
                                                        right: 4,
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                <div
                                    className="child d-flex justify-content-evenly "
                                    style={{
                                        cursor: "pointer",
                                        paddingInline: 10,
                                        backgroundColor: "#f46363",
                                        borderRadius: 12,
                                        rowGap: 6,
                                        minHeight: 86,
                                        padding: 0,
                                    }}
                                >
                                    <div
                                        onClick={() => showCardModal(true)}
                                        className="d-flex align-items-center justify-content-center"
                                        style={{ width: "40%" }}
                                    >
                                        <p
                                            style={{
                                                margin: 0,
                                                color: "#ffffff",
                                                fontSize: 12,
                                            }}
                                        >
                                            CARD
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            border: "1px solid rgb(233, 146 ,145,0.4)",
                                        }}
                                    />

                                    <div
                                        onClick={() => showACHModal(true)}
                                        className="d-flex align-items-center justify-content-center"
                                        style={{ width: "40%" }}
                                    >
                                        <p
                                            style={{
                                                margin: 0,
                                                color: "#ffffff",
                                                fontSize: 12,
                                            }}
                                        >
                                            ACH
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {/* Buttons  */}
            <div
                className="d-flex align-items-center justify-content-between submit-btn-give halffield"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {donateLoader ? (
                    <Circles
                        height="40"
                        width="40"
                        color="#fff"
                        ariaLabel="circles-loading"
                        wrapperClass=""
                        visible={true}
                    />
                ) : (
                    <input
                        style={{
                            background: getGradient(btnBg),
                            color: txtColor,
                            fontSize: Number(buttonFont.size),
                            fontFamily:
                                buttonFont.family === "default"
                                    ? universalStyles.font
                                    : buttonFont.family
                                    ? buttonFont.family
                                    : "sans-serif",
                            fontStyle: buttonFont.isItalic
                                ? "italic"
                                : "normal",
                            textDecorationLine: buttonFont.isUnderlined
                                ? "underline"
                                : "none",
                            fontWeight: buttonFont.isBold ? "bold" : "normal",
                        }}
                        type="submit"
                        value={"Give Now"}
                        onClick={() => donateNow()}
                    />
                )}
                <div
                    onClick={() => dispatch(setDonateDropDown(!dropDown))}
                    className="align-items-center justify-content-center"
                    style={{
                        backgroundColor: "#f3f2f2a1",
                        borderRadius: 10,
                        padding: 8,
                        cursor: "pointer",
                    }}
                >
                    {dropDown ? (
                        <ArrowUp
                            strokeWidth={3}
                            fill="transparent"
                            color="#fb5454"
                        />
                    ) : (
                        <ArrowDown
                            strokeWidth={3}
                            fill="transparent"
                            color="#fb5454"
                        />
                    )}
                </div>
            </div>
            {/* Cancel Modal */}
            <Modal
                size={"lg"}
                centered
                show={cancelModal}
                onHide={() => showCancelModal(false)}
                backdrop="static"
            >
                <Modal.Body className="cancel-modal col-md-12" style={{}}>
                    <div className="d-flex col-md-12 flex-column align-items-center justify-content-center">
                        <CircleAlert size={50} color="#f8bb86" />
                        <h3
                            style={{
                                color: "#000",
                                fontWeight: "600",
                                marginTop: 20,
                            }}
                        >
                            Are you sure?
                        </h3>
                        <h6 style={{ color: "#000", marginTop: 10 }}>
                            Once cancelled, you will not be able to recover this
                            subscription?
                        </h6>
                    </div>
                    <div className="d-flex col-md-12 align-items-center justify-content-end gap-3 mt-3">
                        <input
                            style={{
                                borderRadius: 5,
                                backgroundColor: "#efefef",
                                color: "red",
                                padding: 10,
                                width: "20%",
                            }}
                            type="button"
                            value={"Cancel"}
                            onClick={() => showCancelModal(false)}
                        />
                        <button
                            onClick={() => cancelSubscription()}
                            style={{
                                borderRadius: 5,
                                backgroundColor: "#e64942",
                                padding: 10,
                                color: "#fff",
                                paddingInline: 20,
                            }}
                        >
                            {cancelLoader ? (
                                <Circles
                                    height="20"
                                    width="20"
                                    color="#fff"
                                    ariaLabel="circles-loading"
                                    wrapperClass=""
                                    visible={true}
                                />
                            ) : (
                                "OK"
                            )}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Edit Modal */}
            <Modal
                size={"lg"}
                centered
                show={editModal}
                onHide={() => showEditModal(false)}
                backdrop="static"
            >
                <Modal.Body
                    style={{ padding: 0 }}
                    className="cancel-modal col-md-12"
                >
                    <div
                        className="confirmation-dialog"
                        style={{
                            backgroundColor: "black",
                            borderRadius: 10,
                            padding: 20,
                        }}
                    >
                        <div className="d-flex justify-content-between">
                            <h4 style={{ fontSize: 20, color: "#fff" }}>
                                Edit Subscription
                            </h4>
                            <X
                                onClick={() => showEditModal(false)}
                                size={20}
                                fill="#8F9090"
                                color="#8F9090"
                                strokeWidth={5}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                        <div className="d-flex col-md-12 inputCont align-items-center mt-3 ">
                            <input
                                className="givingInput"
                                value={editAmount}
                                type="number"
                                onChange={(e) => {
                                    const val = e.target.value;
                                    if (val.length <= 9 && val.length >= 0) {
                                        setEditAmount(val);
                                    }
                                }}
                                placeholder="Enter amount"
                                style={{ padding: 10 }}
                            />
                        </div>

                        <div
                            className="mt-3 d-flex align-items-center justify-content-end"
                            style={{ columnGap: 6 }}
                        >
                            {frequencyOptions.map((item) => {
                                return (
                                    <div
                                        key={item.id}
                                        onClick={() => {
                                            setEditFrequencyId(item.id);
                                        }}
                                        style={{
                                            backgroundColor:
                                                editFrequencyId === item.id
                                                    ? "black"
                                                    : "#fb6262",
                                            borderRadius: 5,
                                            padding: 14,
                                            cursor: "pointer",
                                            border:
                                                editFrequencyId === item.id
                                                    ? "1px solid #fff"
                                                    : "unset",
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: 12,
                                                lineHeight: "0px",
                                                color:
                                                    editFrequencyId === item.id
                                                        ? "#fb6262"
                                                        : "#fff",
                                                margin: 0,
                                            }}
                                        >
                                            {item.name}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="mt-3">
                            <h4
                                style={{
                                    margin: 0,
                                    color: "#fff",
                                    fontSize: 14,
                                }}
                            >
                                Cards list
                            </h4>
                            <div className="parent align-items-center paymentMethods">
                                {giving?.cards &&
                                    giving.cards.length > 0 &&
                                    giving.cards.map((item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                onClick={() =>
                                                    setEditCustomer({
                                                        card_id: item.id,
                                                        customer: item.customer,
                                                    })
                                                }
                                                className="child d-flex flex-column justify-content-between"
                                                style={{
                                                    cursor: "pointer",
                                                    padding: 14,
                                                    backgroundColor: "#f46363",
                                                    borderRadius: 12,
                                                    rowGap: 6,
                                                    minHeight: 86,
                                                    border:
                                                        editCustomer.card_id ===
                                                        item.id
                                                            ? "1px solid #fff"
                                                            : "unset",
                                                    boxShadow:
                                                        editCustomer.card_id ===
                                                        item.id
                                                            ? "rgb(139 139 139) 0px 1px 5px 0px"
                                                            : "unset",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: "#ffffff",
                                                        lineHeight: "10px",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {item.brand}
                                                </p>
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: "#ffffff",
                                                        lineHeight: "10px",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    **** **** **** {item.last4}
                                                </p>
                                            </div>
                                        );
                                    })}
                                {giving?.ach &&
                                    giving.ach.length > 0 &&
                                    giving.ach.map((item) => {
                                        return (
                                            <div
                                                key={item.id}
                                                onClick={() =>
                                                    setEditCustomer({
                                                        card_id: item.id,
                                                        customer: item.customer,
                                                    })
                                                }
                                                className="child d-flex flex-column justify-content-between"
                                                style={{
                                                    cursor: "pointer",
                                                    padding: 14,
                                                    backgroundColor: "#f46363",
                                                    borderRadius: 12,
                                                    rowGap: 6,
                                                    minHeight: 86,
                                                    border:
                                                        editCustomer.card_id ===
                                                        item.id
                                                            ? "1px solid #fff"
                                                            : "unset",
                                                    boxShadow:
                                                        editCustomer.card_id ===
                                                        item.id
                                                            ? "rgb(139 139 139) 0px 1px 5px 0px"
                                                            : "unset",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: "#ffffff",
                                                        lineHeight: "10px",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {item.bank_name}
                                                </p>
                                                <p
                                                    style={{
                                                        margin: 0,
                                                        color: "#ffffff",
                                                        lineHeight: "10px",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    **** **** **** {item.last4}
                                                </p>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div
                            className="d-flex my-3"
                            style={{ border: "1px solid #fff" }}
                        />
                        <div
                            className="d-flex align-items-center justify-content-end"
                            style={{ columnGap: 4 }}
                        >
                            <button
                                disabled={updateLoader}
                                onClick={updateSubscription}
                                style={{
                                    backgroundColor: "#28a745",
                                    color: "#fff",
                                    padding: 6,
                                    borderRadius: 5,
                                }}
                            >
                                {updateLoader ? (
                                    <Circles
                                        height="20"
                                        width="20"
                                        color="#fff"
                                        ariaLabel="circles-loading"
                                        wrapperClass=""
                                        visible={true}
                                    />
                                ) : (
                                    "Update"
                                )}
                            </button>

                            <button
                                onClick={() => showEditModal(false)}
                                style={{
                                    backgroundColor: "#dc3545",
                                    color: "#fff",
                                    padding: 6,
                                    borderRadius: 5,
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* ACH Modal */}
            <Modal
                size={"lg"}
                centered
                show={achModal}
                onHide={() => showACHModal(false)}
                backdrop="static"
            >
                <Modal.Body
                    style={{ padding: 0 }}
                    className="cancel-modal col-md-12"
                >
                    <div
                        className="confirmation-dialog"
                        style={{
                            backgroundColor: "#1F2020",
                            borderRadius: 10,
                            padding: 20,
                        }}
                    >
                        <div className="d-flex justify-content-between">
                            <h4 style={{ fontSize: 20, color: "#fff" }}>
                                Add New Bank
                            </h4>
                            <X
                                onClick={() => showACHModal(false)}
                                size={20}
                                fill="#8F9090"
                                color="#8F9090"
                                strokeWidth={5}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                        <div className="d-flex flex-column mt-4">
                            <div className="d-flex col-md-12 inputCont align-items-center">
                                <input
                                    className="givingInput2"
                                    value={achName}
                                    type="text"
                                    min={0}
                                    onChange={(e) => setAchName(e.target.value)}
                                    placeholder="Account Holder Name"
                                    style={{ padding: 10, width: "90%" }}
                                />
                            </div>
                            <div className="d-flex col-md-12 inputCont align-items-center ">
                                <input
                                    className="givingInput2"
                                    value={achRouteNumber}
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        setRouteNumber(e.target.value)
                                    }
                                    placeholder="Routing Number"
                                    style={{ padding: 10, width: "90%" }}
                                />
                            </div>
                            <div className="d-flex col-md-12 inputCont align-items-center">
                                <input
                                    className="givingInput2"
                                    value={achAccountNumber}
                                    type="number"
                                    min={0}
                                    onChange={(e) =>
                                        setAccountNumber(e.target.value)
                                    }
                                    placeholder="Account Number"
                                    style={{ padding: 10, width: "90%" }}
                                />
                            </div>
                        </div>

                        <div className="d-flex my-3" />
                        <div
                            className="d-flex align-items-center justify-content-end"
                            style={{ columnGap: 4 }}
                        >
                            <button
                                disabled={achLoader}
                                onClick={addNewAch}
                                style={{
                                    backgroundColor: "#28a745",
                                    color: "#fff",
                                    padding: 6,
                                    paddingInline: 10,
                                    borderRadius: 5,
                                }}
                            >
                                {achLoader ? (
                                    <Circles
                                        height="20"
                                        width="20"
                                        color="#fff"
                                        ariaLabel="circles-loading"
                                        wrapperClass=""
                                        visible={true}
                                    />
                                ) : (
                                    "Add"
                                )}
                            </button>

                            <button
                                onClick={() => showACHModal(false)}
                                style={{
                                    backgroundColor: "#dc3545",
                                    color: "#fff",
                                    padding: 6,
                                    borderRadius: 5,
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* CARD Modal */}
            <Modal
                size={"lg"}
                centered
                show={cardModal}
                onHide={() => showCardModal(false)}
                backdrop="static"
            >
                <Modal.Body
                    style={{ padding: 0 }}
                    className="cancel-modal col-md-12"
                >
                    <Elements stripe={stripePromise}>
                        <CardForm
                            showCardModal={showCardModal}
                            requestGiving={requestGiving}
                        />
                    </Elements>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default GivingDonateWidget;
