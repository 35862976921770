import React, { useState, useEffect, useRef } from "react";
// import GridLayout from "react-grid-layout";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
//Components
import ConnectWidget from "../../pages/Widgets/connectWidget";
import GivingWidget from "../../pages/Widgets/givingWidget";
import GroupWidget from "../../pages/Widgets/groupWidget";
import QuoteWidget from "../../pages/Widgets/quoteWidget";
import GroupWidget2 from "../../pages/Widgets/groupWidget2";
import JoinWidget from "../../pages/Widgets/joinWidget";
import "./styles.css";
import "../../pages/home/styles.css";
import EventListWidget from "../../pages/Widgets/eventListWidget";
import CustomWidget from "../../pages/Widgets/customWidget";
import ColumnWidget from "../../pages/Widgets/columnWidget";
import RowWidget from "../../pages/Widgets/rowWidget";
import SingleWidget from "../../pages/SingleWidget";
import GivingDonateWidget from "../../pages/Widgets/givingDonateWidget";
import GivingGoalWidget from "../../pages/Widgets/GivingGoal";
import AnnualBudgetWidget from "../../pages/Widgets/AnnualBudget";
import BudgetResourceWidget from "../../pages/Widgets/BudgetResources";
import GivingStatement from "../../pages/Widgets/GivingStatement";
import HaveGiven from "../../pages/Widgets/HaveGiven";
import GivingGraph from "../../pages/Widgets/GivingGraph";
import GroupListWidget from "../../pages/Widgets/GroupListWidget";
import CalenderWidget from "../../pages/Widgets/CalenderWidget";
import SongsWidget from "../../pages/Widgets/SongsWidget";
import TeamChatWidget from "../../pages/Widgets/TeamChatWidget";
import TeamMemberWidget from "../../pages/Widgets/TeamMemberWidget";
import TeamDetailWidget from "../../pages/Widgets/TeamDetailWidget";

const GridLayout = WidthProvider(RGL);

const TeamGrid = ({ widgetsOrder }) => {
  const dispatch = useDispatch();
  const pageId = useSelector((state) => state.login.pageId);
  const styles = useSelector((state) => state.bodyStyling.universalStyles);
  const [widgets, setWidgets] = useState([]);
  const [playerHeight, setPlayerHeight] = useState(6);

  const goalDropDown = useSelector((state) => state.giving.goalDropDown);
  const donateDropDown = useSelector((state) => state.giving.donateDropDown);

  useEffect(() => {
    if (widgetsOrder) {
      setWidgets(widgetsOrder);
    }
  }, [widgetsOrder]);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getRowHeight = () => {
    if (window.innerWidth > 1536 && window.innerWidth <= 1707) {
      return 110;
    } else if (window.innerWidth > 1707 && window.innerWidth <= 1920) {
      return 120;
    } else if (window.innerWidth > 1920 && window.innerWidth <= 2048) {
      return 115;
    } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
      return 120;
    } else if (window.innerWidth > 2304 && window.innerWidth <= 2389) {
      return 110;
    } else if (window.innerWidth > 2389 && window.innerWidth <= 2688) {
      return 120;
    } else if (window.innerWidth > 2688 && window.innerWidth <= 3072) {
      return 150;
    } else if (window.innerWidth > 3072 && window.innerWidth <= 4608) {
      return 150;
    }
    return 100;
  };

  // console.log("WIDTH>", screenSize.width);
  const gridElement = document.querySelector(".layout");

  return (
    <>
      <GridLayout
        className="layout"
        cols={screenSize.width < 1000 ? 1 : 6}
        rowHeight={getRowHeight()}
        // width={screenSize.width - screenSize.width * 0.15}
        width={
          gridElement
            ? gridElement.clientWidth - 20
            : screenSize.width - screenSize.width * 0.15
        }
        autoSize
        isBounded
        isResizable={false}
        allowOverlap={false}
        margin={styles.corners === "sharp" ? [0, 0] : [20, 20]}
        containerPadding={[0, 0]}
        isDraggable={false}
      >
        {widgets &&
          widgets.map((item) => {
            return (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  ...(item.widgetInfo.name === "player" && {
                    borderRadius: styles.corners === "sharp" ? 0 : 20,
                  }),
                  // z-index 1 for giving widget calendar to show on top of other widgets
                  zIndex: item.widgetInfo.name === "giving-donate" ? 1 : 0,
                }}
                key={item.widget_id}
                data-grid={{
                  x: item.x,
                  y: item.y,
                  w: screenSize.width < 1000 ? 1 : item.w,
                  h:
                    item.widgetInfo.name === "watch_player"
                      ? playerHeight
                      : (item.widgetInfo.name === "giving-donate" &&
                          donateDropDown) ||
                        (item.widgetInfo.name === "giving-goal" && goalDropDown)
                      ? 6
                      // : item.widgetInfo.name === "team-member" &&
                      //   item.h == 3 &&
                      //   screenSize.width >= 2389
                      // ? 2.5
                      // : item.h == 3 && screenSize.width >= 1792
                      // ? 2.5
                      // : item.h == 6 && screenSize.width >= 1792
                      // ? 5
                      : item.h,
                }}
              >
                {item.widgetInfo.name === "connect" ? (
                  <ConnectWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    x={item.x}
                  />
                ) : // item.widget_id == "3"
                item.widgetInfo.name === "giving" ? (
                  <GivingWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "ourcode" ? (
                  <QuoteWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "map" ? (
                  <GroupWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "serve" ? (
                  <GroupWidget2
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "groups" ? (
                  <JoinWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "eventList" ? (
                  <EventListWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "column" ? (
                  <ColumnWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "row" ? (
                  <RowWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "default_widget" ? (
                  <CustomWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "watch" ? (
                  <SingleWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "giving-donate" ? (
                  <GivingDonateWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "giving-goal" ? (
                  <GivingGoalWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "annual-budget" ? (
                  <AnnualBudgetWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "budgeting-resources" ? (
                  <BudgetResourceWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "giving-statement" ? (
                  <GivingStatement
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "have-given" ? (
                  <HaveGiven
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "giving-graph" ? (
                  <GivingGraph
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "group-list" ? (
                  <GroupListWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                    groupData={item.group}
                  />
                ) : item.widgetInfo.name === "calendar" ? (
                  <CalenderWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "songs" ? (
                  <SongsWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "team-chat" ? (
                  <TeamChatWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "team-member" ? (
                  <TeamMemberWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                    memberData={item.member}
                  />
                ) : item.widgetInfo.name === "team-details" ? (
                  <TeamDetailWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    x={item.x}
                  />
                ) : null}
              </div>
            );
          })}
      </GridLayout>
    </>
  );
};

export default TeamGrid;
