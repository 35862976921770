import React, { useState, useEffect } from "react";
import "../../home/styles.css";
import { useDispatch, useSelector } from "react-redux";
import Assets from "../../../assets/images";
import { getGradient } from "../../../utills/getGradient";
import { ArrowDown, ArrowUp } from "lucide-react";
import {
  setGivingData,
  setGoalDropDown,
} from "../../../store/slices/givingSlice";
import { getRequest, postRequest } from "../../../utills/requests";
import { submitRequest } from "../../../store/slices/bodyStyling";
import toast from "react-hot-toast";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const GivingGoalWidget = ({ itemStyles, width, height, widgetId }) => {
  const [border, setBorder] = useState("");
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const user = useSelector((state) => state.login.userData);
  const dropDown = useSelector((state) => state.giving.goalDropDown);
  const givingData = useSelector((state) => state.giving.givingData);

  const { default_widget_colors, mainColor } = universalStyles;
  const [defaultCorners, setDefaultCorners] = useState("true");
  const dispatch = useDispatch();
  const frequencyOptions = [
    { id: 1, name: "Weekly" },
    { id: 2, name: "Bi-Weekly" },
    { id: 3, name: "Monthly" },
  ];

  const [givingGoal, setGivingGoal] = useState(0);

  useEffect(() => {
    if (givingData?.giving?.goal) {
      setGivingGoal(givingData?.giving?.goal?.amount);
      // setState(mapping[givingData?.giving?.goal?.interval]);
      setFrequencyId(givingData?.giving?.goal?.interval);
    }
  }, [givingData]);

  const [frequencyId, setFrequencyId] = useState(1);

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      buttonFont.isDefaultFont == 1 &&
        setButtonFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  useEffect(() => {
    if (itemStyles) {
      // setColor(itemStyles.background_color);
      if (itemStyles.background_color) {
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      }
      setBtnTxt(itemStyles.button_text);
      // setBtnBg(itemStyles.button_background_color);
      if (itemStyles.button_background_color) {
        try {
          const bgstyle = JSON.parse(itemStyles.button_background_color);
          setBtnBg(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.button_background_color;
          setBtnBg(bgstyle);
        }
      }
      setTxtColor(itemStyles.button_text_color);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }
      if (itemStyles.button_font_style) {
        try {
          const buttonStyle = JSON.parse(itemStyles.button_font_style);
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
        } catch (error) {
          const buttonStyle = itemStyles.button_font_style;
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
          console.error("Error parsing heading title font style:", error);
        }
      }

      setLink(itemStyles.widget_link);
      setBtnLink(itemStyles.button_link);
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
    }
  }, [itemStyles]);

  const [isDefaultButton, setDefaultButton] = useState(0);
  const [buttonFont, setButtonFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 18,
    isDefaultFont: 1,
  });

  const [isDefault, setDefault] = useState(0);
  const [color, setColor] = useState("#f8acac");
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "frontage",
    size: 22,
    isDefaultFont: 1,
  });
  const [btnBg, setBtnBg] = useState("");
  const [btnTxt, setBtnTxt] = useState("");
  const [txtColor, setTxtColor] = useState("");
  const [link, setLink] = useState("");
  const [btnlink, setBtnLink] = useState("");

  const changeGoal = async (interval) => {
    const data = {
      amount: givingGoal,
      interval,
    };
    try {
      dispatch(submitRequest(true));
      const response = await postRequest({
        endpoint: "/setUserGoal",
        payload: data,
      });
      console.log("RESP>", response.data);
      toast.success("Goal updated successfully.", { duration: 2500 });
      if (givingData && givingData?.giving?.goal?.amount !== givingGoal) {
        requestGiving();
      }
    } catch (error) {
      console.log("ERR>", error);
    } finally {
      if (givingData && givingData?.giving?.goal?.amount == givingGoal) {
        dispatch(submitRequest(false));
      }
    }
  };

  const requestGiving = async () => {
    try {
      const response = await getRequest({
        endpoint: `/getGiving?user_id=${user.id}`,
      });
      console.log("RESP>", response);
      dispatch(setGivingData(response));
    } catch (error) {
      console.log("ERROR>", error?.response);
    }finally{
      dispatch(submitRequest(false))
    }
  };

  return (
    <div
      className={` givingGoal `}
      style={{
        background: `${
          isDefault && default_widget_colors.giving
            ? default_widget_colors.giving.is_default === "0"
              ? default_widget_colors.giving.color
              : default_widget_colors.giving.is_default === "1"
              ? mainColor
              : getGradient(color)
            : getGradient(color)
        }`,
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
      }}
    >
      {/* <div className="progessbar" /> */}
      <div className="col-md-2" style={{ height: "100%" }}>
        <Progress.Line
          showInfo={false}
          strokeWidth={40}
          vertical
          percent={
            givingData?.giving?.goal?.percentage
              ? givingData.giving.goal.percentage
              : 0
          }
          strokeColor="#fb5f5f"
          style={{ opacity: 0.9 }}
        />
      </div>
      <div className="contentcenter" style={{ width: "100%" }}>
        {/* <svg viewBox="0 0 10 3">
          <text
            x="5"
            y="2"
            text-anchor="middle"
            font-size="1.3"
            fill="none"
            stroke-width=".03"
            stroke="#fb6d6d"
          >
            80%
          </text>
        </svg> */}
        <div className="d-flex flex-column align-items-center">
          <h1 className="outline-Text">
            {givingData?.giving?.goal?.percentage
              ? givingData.giving.goal.percentage
              : 0}
            %
          </h1>
          <h3 style={{ lineHeight: "unset", fontSize: 25 }}>
            $
            {givingData?.giving?.goal?.paid_amount
              ? givingData.giving.goal.paid_amount
              : 0}
            | $
            {parseInt(
              givingData?.giving?.goal?.amount
                ? givingData?.giving?.goal?.amount
                : 0
            )}
          </h3>
          <h6 style={{ marginTop: 10, fontSize: 16, fontWeight: "bold" }}>
            Your are at this amount of your giving goal!
          </h6>
          {/* Dropdown content */}
          {dropDown && (
            <div
              className="d-flex flex-column align-items-end"
              style={{ width: "100%", marginTop: 30 }}
            >
              <span
                style={{ color: "#fa7373", fontSize: 20, fontWeight: "600" }}
              >
                i want to give
              </span>
              <input
                className="goalInput"
                value={givingGoal}
                onChange={(e) => setGivingGoal(e.target.value)}
                type="number"
                placeholder="$"
                style={{
                  borderBottom: "1px solid #fa54543d",
                  width: "100%",
                  color: "#000",
                }}
              />
              {/* Options */}
              <div
                className="d-flex align-items-center justify-content-end flex-wrap gap-2"
                style={{ width: "100%", marginBlock: 20 }}
              >
                {frequencyOptions.map((item) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => {
                        changeGoal(item.id);
                        setFrequencyId(item.id);
                      }}
                      style={{
                        backgroundColor:
                          frequencyId !== item.id ? "#fa7373" : "#000",
                        borderRadius: 5,
                        paddingBlock: 6,
                        paddingInline: 14,
                        cursor: "pointer",
                      }}
                    >
                      <h6
                        style={{
                          color: frequencyId == item.id ? "#fa7373" : "#fff",
                          margin: 0,
                          fontSize: 12,
                        }}
                      >
                        {item.name}
                      </h6>
                    </div>
                  );
                })}
              </div>
              <h4 style={{ color: "#000", textAlign: "end", fontSize: 18 }}>
                REDEFINE GIVING
                <br />
                STARTS WITH
                <br />
                ACCOUNTABILITY
              </h4>
            </div>
          )}
        </div>
        <div
          onClick={() => dispatch(setGoalDropDown(!dropDown))}
          className="align-items-center justify-content-center"
          style={{
            backgroundColor: "#f3f2f2a1",
            borderRadius: 10,
            padding: 8,
            cursor: "pointer",
          }}
        >
          {dropDown ? (
            <ArrowUp strokeWidth={3} fill="transparent" color="#fb5454" />
          ) : (
            <ArrowDown strokeWidth={3} fill="transparent" color="#fb5454" />
          )}
        </div>
      </div>
    </div>
  );
};

export default GivingGoalWidget;
