import React, { useEffect, useState, useRef } from "react";
//Components
import Player from "./Player";
// Redux
import { useDispatch, useSelector } from "react-redux";
// Styles.css
import "../home/styles.css";
import Chat from "./Chat";
import Notes from "./Notes";
import Sermon from "./Sermon";
import PlayerWidget from "./Player";
import {
  setCurrentSelectedEvent,
  setEventId,
  setEventNoteText,
  setLiveEvent,
  setLiveStreamChat,
  setSelectedEventDeatails,
  setSelectedStreamID,
  setSeriesId,
  setSermonsData,
} from "../../store/slices/watchSlice";
// Firebase
import { database } from "../../firebase/firebaseConfig.js";
import { ref, onChildAdded, off, onValue } from "firebase/database";
import toast from "react-hot-toast";

const SingleWidget = ({ itemStyles, height }) => {
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const data = useSelector((state) => state.watch.watchData);
  const sermonsData = useSelector((state) => state.watch.sermonsData);

  const dispatch = useDispatch();
  const sermonsDataRef = useRef(sermonsData);

  useEffect(() => {
    sermonsDataRef.current = sermonsData;
  }, [sermonsData]);

  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");
  // const [arrangedData, setArrangedData] = useState(null);

  // Itemstyles
  useEffect(() => {
    if (itemStyles) {
      setDefaultCorners(itemStyles.defaultCorners);
      setBorder(itemStyles.border);
    }
  }, [itemStyles]);

  // Universal styles
  useEffect(() => {
    if (universalStyles.corners && itemStyles.defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
  }, [universalStyles.corners, itemStyles]);

  let newItems = false;

  useEffect(() => {
    // add chat message listener
    const liveStreamRef = ref(database, "LiveStream");
    onChildAdded(liveStreamRef, handleLiveStreamStatus);

    // handle initial items
    onValue(liveStreamRef, handleInitialItems);
  }, []);

  const handleInitialItems = () => {
    newItems = true;
  };

  const handleLiveStreamStatus = (snapshot) => {
    if (!newItems) return;
    if (snapshot.exists()) {
      const sermonsData = sermonsDataRef.current;
      console.log("LIVE STREAM>>", snapshot.val());
      let e = snapshot.val();
      if (e.status == 1) {
        const tempData = {
          event_id: e.event_id,
          stream_session_id: e.stream_session_id,
          series_id: e.series_id,
          status: e.status,
          id: e.series_id,
          playCheck: true,
          chat: [],
          notes: [],
        };
        dispatch(setLiveEvent(tempData));
        dispatch(setSeriesId(e.series_id));
        dispatch(setEventId(e.event_id));
        dispatch(setSelectedEventDeatails(tempData));
        dispatch(setEventNoteText(""));
        toast.success("Live stream started", { duration: 6000 });
      } else {
        dispatch(setLiveEvent({}));
        const data = {
          playCheck: true,
          status: 0,
          stream_session_id:
            sermonsData?.series?.[0]?.events?.[0]?.stream_session_id,
          series_id: sermonsData?.series?.[0]?.series_id,
          event_id: sermonsData?.series?.[0]?.events?.[0]?.event_id,
          chat: sermonsData?.series?.[0]?.events?.[0]?.chat,
          notes: sermonsData?.series?.[0]?.events?.[0]?.notes,
        };
        dispatch(setSelectedEventDeatails(data));
        if (sermonsData?.series?.[0]?.events?.[0]?.notes?.[0]?.note_text) {
          dispatch(
            setEventNoteText(
              sermonsData?.series?.[0]?.events?.[0]?.notes?.[0]?.note_text
            )
          );
        } else {
          dispatch(setEventNoteText(""));
        }
        dispatch(setSeriesId(sermonsData?.series?.[0]?.series_id));
        dispatch(setEventId(sermonsData?.series?.[0]?.events?.[0]?.event_id));
        toast.error("Live stream ended.", { duration: 6000 });
      }
    }
  };

  // WatchData
  useEffect(() => {
    if (data?.series && data?.events) {
      groupEventsBySeries(data.series, data.events, data?.selectedSeries);
    }
  }, [data]);

  const groupEventsBySeries = (series, events, selectedSeries) => {
    const groupedEvents = {};
    // Group events by series ID
    events.forEach((event) => {
      const seriesId = event.series_id;
      if (!groupedEvents[seriesId]) {
        groupedEvents[seriesId] = [];
      }
      groupedEvents[seriesId].push(event);
    });
    // Create new objects for each series and assign properties
    const resultSeries = series.map((seriesItem) => {
      const seriesId = seriesItem.series_id;
      return {
        ...seriesItem,
        events: groupedEvents[seriesId] || [],
      };
    });
    // Append selectedSeries to the result
    const tempData = {
      series: resultSeries,
      selectedSeries: selectedSeries || [],
    };
    if (selectedSeries && selectedSeries?.length > 0) {
      dispatch(setLiveEvent(selectedSeries[0]));
      const data = {
        playCheck: true,
        ...selectedSeries[0],
        series_id: selectedSeries[0]?.id,
      };
      dispatch(setSelectedEventDeatails(data));
      // Notes
      if (selectedSeries?.[0]?.notes?.[0]?.note_text) {
        dispatch(setEventNoteText(selectedSeries?.[0]?.notes?.[0]?.note_text));
      } else {
        dispatch(setEventNoteText(""));
      }
      // series id and event id
      dispatch(setSeriesId(selectedSeries[0]?.id));
      dispatch(setEventId(selectedSeries[0]?.event_id));
    } else if (resultSeries && resultSeries.length > 0) {
      const data = {
        playCheck: true,
        status: 0,
        stream_session_id: resultSeries[0]?.events?.[0]?.stream_session_id,
        series_id: resultSeries[0]?.series_id,
        event_id: resultSeries[0]?.events?.[0]?.event_id,
        chat: resultSeries[0]?.events?.[0]?.chat,
        notes: resultSeries[0]?.events?.[0]?.notes,
      };
      dispatch(setSelectedEventDeatails(data));
      if (resultSeries[0]?.events?.[0]?.notes?.[0]?.note_text) {
        dispatch(
          setEventNoteText(resultSeries[0]?.events?.[0]?.notes?.[0]?.note_text)
        );
      } else {
        dispatch(setEventNoteText(""));
      }
      dispatch(setSeriesId(resultSeries[0]?.series_id));
      dispatch(setEventId(resultSeries[0]?.events?.[0]?.event_id));
    }

    dispatch(setSermonsData(tempData));
    // console.log("RES>>", tempData);
  };

  return (
    <div
      className={`d-flex flex-column justify-content-between `}
      style={{
        height: border === "round" ? "98%" : "100%",
        width: "100%",
        borderRadius: border === "round" ? 20 : border === "sharp" ? 0 : null,
        rowGap: border === "round" ? 20 : 0,
      }}
    >
      {/* 1st Row */}
      <div
        className="d-flex justify-content-between"
        style={{ height: "60%", columnGap: border === "round" ? 20 : 0 }}
      >
        <PlayerWidget borderRadius={border === "round" ? 20 : 0} />
        <Chat borderRadius={border === "round" ? 20 : 0} />
      </div>
      {/* 2nd Row */}
      <div
        className="d-flex justify-content-between"
        style={{ height: "40%", columnGap: border === "round" ? 20 : 0 }}
      >
        <Notes borderRadius={border === "round" ? 20 : 0} />
        <Sermon borderRadius={border === "round" ? 20 : 0} />
      </div>
    </div>
  );
};

export default SingleWidget;
