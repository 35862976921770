import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
//Assets
// import Assets from "../../assets/images";
//Actions
import { getStyling } from "../../store/slices/bodyStyling";
//Components
import TeamGrid from "../../components/GridLayout/TeamGrid";

function TeamPage(props) {
  const { darkMode } = props;

  const dispatch = useDispatch();

  const data = useSelector((state) => state.bodyStyling.styling);
  const loading = useSelector((state) => state.bodyStyling.isLoading);

  useEffect(() => {
    dispatch(getStyling(5));
  }, []);

  return (
    <div
      className={
        darkMode ? "rightWrapMain darkMode" : "rightWrapMain lightMode"
      }
    >
      <div className="rightInnerWrap">
        <div className="sec2">
          <div className="container">
            {loading ? (
              <Circles
                height="50"
                width="50"
                // color="#4b6cb7"
                color="transparent"
                ariaLabel="circles-loading"
                wrapperStyle={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TeamGrid widgetsOrder={data.homepageOrder} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamPage;
