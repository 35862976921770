import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
//Component
import ProtectedRoute from "./Routes/ProtectedRoute";
import PublicRoute from "./Routes/PublicRoute";
//Pages
import Home from "./pages/home/Home";
import WatchPage from "./pages/watchPage/WatchPage";
import Giving from "./pages/giving/Giving";
import GroupPage from "./pages/groupPage/GroupPage";
import TeamPage from "./pages/TeamPage";
import TimeLine from "./pages/timeline";
import Post from "./pages/post";
import UpcomingEvent from "./pages/upcomingEvent";
import UserProfile from "./pages/UserProfile";
import OtherUserProfile from "./pages/OtherUserProfile/index";
import ServePage from "./pages/ServePage";
import KidsPage from "./pages/KidsPage";
import AboutUs from "./pages/AboutUs";
import OurBeliefs from "./pages/AboutUs/OurBelief";
import OurCode from "./pages/AboutUs/OurCode";
import OurLeaderShip from "./pages/AboutUs/OurLeadership";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicRoute component={Home} />} />
      <Route path="/serve" element={<PublicRoute component={ServePage} />} />
      <Route path="/kids" element={<PublicRoute component={KidsPage} />} />
      <Route path="/group" element={<PublicRoute component={GroupPage} />} />
      <Route path="/about-us" element={<PublicRoute component={AboutUs} />} />
      <Route path="/our-beliefs" element={<PublicRoute component={OurBeliefs} />} />
      <Route path="/our-code" element={<PublicRoute component={OurCode} />} />
      <Route path="/our-leadership" element={<PublicRoute component={OurLeaderShip} />} />

      <Route path="/watch" element={<ProtectedRoute component={WatchPage} />} />
      <Route path="/giving" element={<ProtectedRoute component={Giving} />} />
      {/* <Route path="/group" element={<ProtectedRoute component={GroupPage} />} /> */}
      <Route path="/team" element={<ProtectedRoute component={TeamPage} />} />
      {/*  */}
      <Route path="/timeline" element={<ProtectedRoute component={TimeLine} />} />
      <Route path="/post" element={<ProtectedRoute component={Post} />} />
      <Route path="/event" element={<ProtectedRoute component={UpcomingEvent} />} />
      <Route path="/userProfile" element={<ProtectedRoute component={UserProfile} />} />
      <Route path="/otherUserProfile" element={<ProtectedRoute component={OtherUserProfile } />} />
    </Routes>
  );
};

export default AppRoutes;
