import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
//Actions
import { getStyling, setLoginModalState } from "../../store/slices/bodyStyling";
import {
  hasError,
  loginSuccess,
  requestLogin,
  setUserProfile,
} from "../../store/slices//loginSlice";
//Components
import GroupGrid from "../../components/GridLayout/GroupGrid";
import TeamGrid from "../../components/GridLayout/TeamGrid";
import ServeGrid from "../../components/GridLayout/ServeGrid";
import Assets from "../../assets/images";
import toast from "react-hot-toast";
import { postRequest } from "../../utills/requests";
import LoginModal from "../../components/Modal/LoginModal";
import { useNavigate } from "react-router-dom";

function ServePage(props) {
  const { darkMode } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.bodyStyling.styling);
  const loading = useSelector((state) => state.bodyStyling.isLoading);
  const loginLoader = useSelector((state) => state.login.isLoading);
  const loginModalState = useSelector(
    (state) => state.bodyStyling.loginModalState
  );

  useEffect(() => {
    dispatch(getStyling(6));
  }, []);

  const handleLogin = async (data) => {
    if (loginLoader) {
      return;
    }
    dispatch(requestLogin(true));
    try {
      const response = await postRequest({
        endpoint: "/loginReact",
        payload: data,
      });
      if (response?.data?.status === true) {
        const userData = {
          ...response.data.user,
          is_part_of_any_team: response.data?.is_part_of_any_team,
        };
        dispatch(loginSuccess(userData));
        dispatch(setUserProfile(response.data.user_profile));
        localStorage.setItem("accessToken", response?.data?.user?.api_token);
        toast.success("Logged In", { duration: 3000 });
        navigate("/");
        dispatch(setLoginModalState(false));
      } else {
        if (response?.data?.message) {
          dispatch(hasError(response?.data?.message));
        }
        console.log("ERR>", response?.data?.message);
      }
    } catch (error) {
      console.log("ERR>", error);
      if (error?.data?.message) {
        toast.error(error.data.message, { duration: 3000 });
      }
    } finally {
      dispatch(requestLogin(false));
    }
  };

  const handleSignup = async (data, setErr) => {
    if (loginLoader) {
      return;
    }
    dispatch(requestLogin(true));
    try {
      const response = await postRequest({
        endpoint: "/registerReact",
        payload: data,
      });
      if (response?.data?.status === true) {
        const userData = {
          ...response.data.user,
          is_part_of_any_team: false,
        };
        dispatch(loginSuccess(userData));
        dispatch(setUserProfile(response.data.user_profile));
        localStorage.setItem("accessToken", response?.data?.user?.api_token);
        toast.success("Logged In", { duration: 3000 });
        // setShow(false);
        navigate("/");
        dispatch(setLoginModalState(false));
      }
    } catch (error) {
      if (error?.response?.data?.errors?.email)
        setErr(error?.response?.data?.errors?.email?.[0]);
    } finally {
      dispatch(requestLogin(false));
    }
  };

  const forgetPassword = async (email, setErr) => {
    if (loginLoader) {
      return;
    }
    dispatch(requestLogin(true));
    try {
      const response = await postRequest({
        endpoint: "/forgetPassword",
        payload: { email },
      });
      if (response?.data?.status === true) {
        toast.success("Logged In", { duration: 3000 });
      }
      console.log("ERR>>>>", response.data);
      if (response?.data?.message) {
        setErr(response.data.message);
      }
    } catch (error) {
      console.log("ERR>>>>", error);
      if (error?.response?.data?.message) {
        setErr(error.response.data.message);
      }
    } finally {
      dispatch(requestLogin(false));
    }
  };

  return (
    <div
      className={
        darkMode ? "rightWrapMain darkMode" : "rightWrapMain lightMode"
      }
    >
      <div className="rightInnerWrap">
        <div className="sec2">
          <div className="container">
            {loading ? (
              <Circles
                height="50"
                width="50"
                // color="#4b6cb7"
                color="transparent"
                ariaLabel="circles-loading"
                wrapperStyle={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <div
                className="d-flex flex-column"
                style={{
                  justifyContent: "center",
                  alignItems:'center'
                }}
              >
                <div className="serve-block">
                  {/* 1st */}
                  <div className="d-flex col-md-10 flex-column align-items-center justify-content-center">
                    <img src={Assets.Serve} style={{ maxWidth: "30%" }} />
                    <p
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        maxWidth: "72%",
                        fontSize: 24,
                        lineHeight: "40px",
                        fontFamily: "sans-serif",
                        marginTop: 30,
                      }}
                    >
                      At Redefine Church, we believe that we are called to be
                      contributors, not consumers. We serve to further our
                      mission;{" "}
                      <i>
                        so that the broken would be redefined to the restored
                        through Jesus Christ.
                      </i>{" "}
                      We believe that there is a place for everyone to serve.
                      Find out how you can serve at Redefine!
                    </p>
                  </div>
                  {/* 2nd */}
                  <div className="d-flex mt-4 col-md-10 align-items-center justify-content-between gap-2">
                    <div className="d-flex flex-column col-md-7">
                      <h3
                        style={{
                          color: "#fff",
                          fontWeight: "bolder",
                          fontFamily: "sans-serif",
                        }}
                      >
                        What is your passion?
                      </h3>
                      <p
                        style={{
                          color: "#fff",
                          maxWidth: "72%",
                          fontSize: 24,
                          lineHeight: "40px",
                          fontFamily: "sans-serif",
                          marginTop: 20,
                        }}
                      >
                        Everyone has something that they are passionate about.
                        Finding the best place for you to serve starts with
                        asking yourself what you love to do most.
                      </p>
                    </div>
                    <div className="col-md-5 d-flex align-items-center justify-content-end">
                      <img src={Assets.Circle} style={{ maxWidth: "80%" }} />
                    </div>
                  </div>
                  {/* 3rd */}
                  <div className="d-flex mt-4 col-md-10 align-items-center justify-content-between gap-2">
                    <div className="col-md-5">
                      <img src={Assets.Circle} style={{ maxWidth: "80%" }} />
                    </div>
                    <div className="d-flex flex-column col-md-7">
                      <h3
                        style={{
                          color: "#fff",
                          fontWeight: "bolder",
                          fontFamily: "sans-serif",
                        }}
                      >
                        What is your Gift?
                      </h3>
                      <p
                        style={{
                          color: "#fff",
                          fontSize: 24,
                          lineHeight: "40px",
                          fontFamily: "sans-serif",
                          marginTop: 20,
                        }}
                      >
                        Each one of us have been blessed with specfic gifts and
                        talents that we can use to serve! Taking a good look at
                        what you are good at is an important step in finding the
                        right area to serve.
                      </p>
                    </div>
                  </div>
                  {/* 4th */}
                  <div className="d-flex flex-column col-md-10 align-items-center">
                    <img src={Assets.Circle} style={{ maxWidth: "30%" }} />
                    <h3
                      style={{
                        color: "#fff",
                        fontWeight: "bolder",
                        fontFamily: "sans-serif",
                        marginBlock: 20,
                      }}
                    >
                      Where do we need you?
                    </h3>
                    <p
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        maxWidth: "72%",
                        fontSize: 24,
                        lineHeight: "40px",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Redefine Church has several areas available for you to
                      serve. We have our Experience Team, Hospitality Team, Kids
                      Team, Tech Team, Worship Team, and Outreach Team
                    </p>
                  </div>
                  {/* 5th */}
                  <div className="d-flex mt-4 col-md-10 align-items-center justify-content-between gap-2">
                    <div className="col-md-5">
                      <img src={Assets.TriCircle} style={{ maxWidth: "80%" }} />
                    </div>
                    <div className="d-flex flex-column col-md-7">
                      <h3
                        style={{
                          color: "#fff",
                          fontWeight: "bolder",
                          fontFamily: "sans-serif",
                        }}
                      >
                        The sweet spot
                      </h3>
                      <p
                        style={{
                          color: "#fff",
                          fontSize: 24,
                          lineHeight: "40px",
                          fontFamily: "sans-serif",
                          marginTop: 20,
                        }}
                      >
                        The sweet spot is where your passions, your gifts, and
                        the church's needs come together. Which team falls into
                        the sweet spot? Let us know by clicking below on the
                        team you think best fits you! By clicking the “I’m
                        Interested” button, an email will be automatically sent
                        to our Serve Team leaders, and we will connect with you
                        shortly
                      </p>
                    </div>
                  </div>
                </div>
                <ServeGrid widgetsOrder={data.homepageOrder} />
                <LoginModal
                  show={loginModalState}
                  onHide={() => dispatch(setLoginModalState(false))}
                  handleLogin={handleLogin}
                  onSignup={handleSignup}
                  forgetPassword={forgetPassword}
                  initialState={3}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServePage;
