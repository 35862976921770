import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
//Assets
// import Assets from "../../assets/images";
//Actions
import { getStyling, setLoginModalState } from "../../store/slices/bodyStyling";
//Components
import GroupGrid from "../../components/GridLayout/GroupGrid";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../utills/requests";
import toast from "react-hot-toast";
import {
  hasError,
  loginSuccess,
  requestLogin,
  setUserProfile,
} from "../../store/slices/loginSlice";
import LoginModal from "../../components/Modal/LoginModal";

function GroupPage(props) {
  const { darkMode } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.bodyStyling.styling);
  const loading = useSelector((state) => state.bodyStyling.isLoading);
  const loginModalState = useSelector(
    (state) => state.bodyStyling.loginModalState
  );
  const loginLoader = useSelector((state) => state.login.isLoading);

  useEffect(() => {
    dispatch(getStyling(4));
  }, []);

  const handleLogin = async (data) => {
    if (loginLoader) {
      return;
    }
    dispatch(requestLogin(true));
    try {
      const response = await postRequest({
        endpoint: "/loginReact",
        payload: data,
      });
      if (response?.data?.status === true) {
        const userData = {
          ...response.data.user,
          is_part_of_any_team: response.data?.is_part_of_any_team,
        };
        dispatch(loginSuccess(userData));
        dispatch(setUserProfile(response.data.user_profile));
        localStorage.setItem("accessToken", response?.data?.user?.api_token);
        toast.success("Logged In", { duration: 3000 });
        navigate("/");
        dispatch(setLoginModalState(false));
      } else {
        if (response?.data?.message) {
          dispatch(hasError(response?.data?.message));
        }
        console.log("ERR>", response?.data?.message);
      }
    } catch (error) {
      console.log("ERR>", error);
      if (error?.data?.message) {
        toast.error(error.data.message, { duration: 3000 });
      }
    } finally {
      dispatch(requestLogin(false));
    }
  };

  const handleSignup = async (data, setErr) => {
    if (loginLoader) {
      return;
    }
    dispatch(requestLogin(true));
    try {
      const response = await postRequest({
        endpoint: "/registerReact",
        payload: data,
      });
      if (response?.data?.status === true) {
        const userData = {
          ...response.data.user,
          is_part_of_any_team: false,
        };
        dispatch(loginSuccess(userData));
        dispatch(setUserProfile(response.data.user_profile));
        localStorage.setItem("accessToken", response?.data?.user?.api_token);
        toast.success("Logged In", { duration: 3000 });
        // setShow(false);
        navigate("/");
        dispatch(setLoginModalState(false));
      }
    } catch (error) {
      if (error?.response?.data?.errors?.email)
        setErr(error?.response?.data?.errors?.email?.[0]);
    } finally {
      dispatch(requestLogin(false));
    }
  };

  const forgetPassword = async (email, setErr) => {
    if (loginLoader) {
      return;
    }
    dispatch(requestLogin(true));
    try {
      const response = await postRequest({
        endpoint: "/forgetPassword",
        payload: { email },
      });
      if (response?.data?.status === true) {
        toast.success("Logged In", { duration: 3000 });
      }
      console.log("ERR>>>>", response.data);
      if (response?.data?.message) {
        setErr(response.data.message);
      }
    } catch (error) {
      console.log("ERR>>>>", error);
      if (error?.response?.data?.message) {
        setErr(error.response.data.message);
      }
    } finally {
      dispatch(requestLogin(false));
    }
  };

  return (
    <div
      className={
        darkMode ? "rightWrapMain darkMode" : "rightWrapMain lightMode"
      }
    >
      <div className="rightInnerWrap">
        <div className="sec2">
          <div className="container">
            {loading ? (
              <Circles
                height="50"
                width="50"
                // color="#4b6cb7"
                color="transparent"
                ariaLabel="circles-loading"
                wrapperStyle={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <GroupGrid widgetsOrder={data.homepageOrder} />
                <LoginModal
                  show={loginModalState}
                  onHide={() => dispatch(setLoginModalState(false))}
                  handleLogin={handleLogin}
                  onSignup={handleSignup}
                  forgetPassword={forgetPassword}
                  initialState={3}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupPage;
