import React, { useState, useEffect, useRef } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
//Components
import ConnectWidget from "../../pages/Widgets/connectWidget";
import GivingWidget from "../../pages/Widgets/givingWidget";
import GroupWidget from "../../pages/Widgets/groupWidget";
import QuoteWidget from "../../pages/Widgets/quoteWidget";
import GroupWidget2 from "../../pages/Widgets/groupWidget2";
import JoinWidget from "../../pages/Widgets/joinWidget";
import "./styles.css";
import "../../pages/home/styles.css";
import EventListWidget from "../../pages/Widgets/eventListWidget";
import CustomWidget from "../../pages/Widgets/customWidget";
import ChatWidget from "../../pages/Widgets/chatWidget";
import NotesWidget from "../../pages/Widgets/NotesWidget";
import SermonWidget from "../../pages/Widgets/SermonWidget";
import WatchPlayerWidget from "../../pages/Widgets/watchPlayerWidget";
import ColumnWidget from "../../pages/Widgets/columnWidget";
import RowWidget from "../../pages/Widgets/rowWidget";
import SingleWidget from "../../pages/SingleWidget";

const WatchGrid = ({ widgetsOrder }) => {
  const dispatch = useDispatch();
  const styles = useSelector((state) => state.bodyStyling.universalStyles);
  const [widgets, setWidgets] = useState([]);
  const [playerHeight, setPlayerHeight] = useState(5);

  useEffect(() => {
    if (widgetsOrder) {
      setWidgets(widgetsOrder);
    }
  }, [widgetsOrder]);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getPlayerHeight = () => {
    const plyerWidget = widgets?.find(
      (item) => item?.widgetInfo?.name === "watch_player"
    );
    return plyerWidget?.w;
  };

  const handleResize = () => {
    if (window.innerWidth < 1940 && window.innerWidth > 1740) {
      if (getPlayerHeight() == 4) {
        setPlayerHeight(6);
      } else {
        setPlayerHeight(5);
      }
    } else if (window.innerWidth >= 1536 && window.innerWidth < 1707) {
      if (getPlayerHeight() == 4) {
        setPlayerHeight(6);
      } else {
        setPlayerHeight(5);
      }
    } else if (window.innerWidth == 1707) {
      if (getPlayerHeight() == 4) {
        setPlayerHeight(6);
      } else {
        setPlayerHeight(5);
      }
    } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
      if (getPlayerHeight() == 4) {
        setPlayerHeight(5.5);
      } else {
        setPlayerHeight(5);
      }
    } else if (window.innerWidth > 2304 && window.innerWidth <= 3702) {
      if (getPlayerHeight() == 4) {
        setPlayerHeight(5.5);
      } else {
        setPlayerHeight(5);
      }
    } else if (window.innerWidth == 2048) {
      if (getPlayerHeight() == 4) {
        setPlayerHeight(5.5);
      } else {
        setPlayerHeight(5);
      }
    } else if (window.innerWidth <= 1490 && window.innerWidth > 1291) {
      setPlayerHeight(5);
    } else if (window.innerWidth <= 1290 && window.innerWidth > 981) {
      setPlayerHeight(5);
    } else if (window.innerWidth <= 990 && window.innerWidth > 891) {
      setPlayerHeight(4);
    } else if (window.innerWidth <= 750 && window.innerWidth > 540) {
      setPlayerHeight(3);
    } else if (window.innerWidth <= 540 && window.innerWidth > 350) {
      setPlayerHeight(2);
    } else if (window.innerWidth <= 350) {
      setPlayerHeight(1.5);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getRowHeight = () => {
    if (window.innerWidth > 1536 && window.innerWidth <= 1707) {
      return 110;
    } else if (window.innerWidth > 1707 && window.innerWidth <= 1920) {
      return 120;
    } else if (window.innerWidth > 1920 && window.innerWidth <= 2048) {
      return 115;
    } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
      return 120;
    } else if (window.innerWidth > 2304 && window.innerWidth <= 2389) {
      return 110;
    } else if (window.innerWidth > 2389 && window.innerWidth <= 2688) {
      return 120;
    } else if (window.innerWidth > 2688 && window.innerWidth <= 3072) {
      return 150;
    } else if (window.innerWidth > 3072 && window.innerWidth <= 3584) {
      return 130;
    } else if (window.innerWidth > 3584 && window.innerWidth <= 4608) {
      return 250;
    }
    return 100;
  };

  // console.log("WIDTH>", screenSize.width);

  const gridElement = document.querySelector(".layout");
  // console.log("W>", gridElement?.clientWidth);

  return (
    <>
      <GridLayout
        className="layout"
        // layout={layout} only needed if no data-grid is provided
        // cols={4}
        cols={screenSize.width < 1000 ? 1 : 4}
        // rowHeight={100}
        rowHeight={getRowHeight()}
        // width={screenSize.width - screenSize.width * 0.16}
        // width={screenSize.width - screenSize.width * 0.15}
        // width={'100%'}
        // width={screenSize.width - screenSize.width * 0.15}
        width={
          gridElement
            ? gridElement.clientWidth - 20
            : screenSize.width - screenSize.width * 0.15
        }
        autoSize
        isBounded
        isResizable={false}
        allowOverlap={false}
        margin={styles && styles.corners === "sharp" ? [0, 0] : [20, 20]}
        containerPadding={[0, 0]}
        isDraggable={false}
      >
        {widgets &&
          widgets.map((item) => {
            return (
              <div
                style={{
                  position: "relative",
                  // width: "100%",
                  ...(item.widgetInfo.name === "player" && {
                    borderRadius: styles.corners === "sharp" ? 0 : 20,
                  }),
                }}
                key={item.widget_id}
                data-grid={{
                  x: item.x,
                  y: item.y,
                  w: screenSize.width < 1000 ? 1 : item.w,
                  // h:
                  //   item.widgetInfo.name === "watch_player" && item.w == 4
                  //     ? playerHeight
                  //     : item.h,
                  // h:
                  //   item.widgetInfo.name === "watch" && screenSize.width <= 1706
                  //     ? 7
                  //     : item.widgetInfo.name === "watch" &&
                  //       screenSize.width <= 1920
                  //     ? 6
                  //     : item.widgetInfo.name === "watch" &&
                  //       screenSize.width <= 2048
                  //     ? 5
                  //     : item.widgetInfo.name === "watch" &&
                  //       screenSize.width <= 2304
                  //     ? 5
                  //     : 4.5
                  //     ? item.widgetInfo.name !== "watch"
                  //       ? item.h
                  //       : 5
                  //     : 5,
                  h:
                    item.widgetInfo.name === "watch"
                      ? screenSize.width <= 1706
                        ? 7
                        : screenSize.width <= 1920
                        ? 6
                        : screenSize.width <= 2048
                        ? 6.3
                        : screenSize.width <= 2304
                        ? 6
                        : screenSize.width <= 2389
                        ? 6.6
                        : screenSize.width <= 2688? 6.2 : 6.5
                      : // : item.h == 3 && screenSize.width >= 1792
                        // ? 2.5
                        // : item.h == 6 && screenSize.width >= 1792
                        // ? 5.5
                        item.h,
                }}
              >
                {
                  // item.widget_id == "2"
                  item.widgetInfo.name === "connect" ? (
                    <ConnectWidget
                      widgetId={Number(item.widget_id)}
                      height={item.h}
                      width={item.w}
                      itemStyles={item.widget_styles[0]}
                      x={item.x}
                    />
                  ) : // item.widget_id == "3"
                  item.widgetInfo.name === "giving" ? (
                    <GivingWidget
                      widgetId={Number(item.widget_id)}
                      height={item.h}
                      width={item.w}
                      itemStyles={item.widget_styles[0]}
                      x={item.x}
                    />
                  ) : // item.widget_id == "6"
                  item.widgetInfo.name === "ourcode" ? (
                    <QuoteWidget
                      widgetId={Number(item.widget_id)}
                      height={item.h}
                      width={item.w}
                      itemStyles={item.widget_styles[0]}
                      x={item.x}
                    />
                  ) : // item.widget_id == "4"
                  item.widgetInfo.name === "map" ? (
                    <GroupWidget
                      widgetId={Number(item.widget_id)}
                      height={item.h}
                      width={item.w}
                      itemStyles={item.widget_styles[0]}
                      x={item.x}
                    />
                  ) : // item.widget_id == "5"
                  item.widgetInfo.name === "serve" ? (
                    <GroupWidget2
                      widgetId={Number(item.widget_id)}
                      height={item.h}
                      width={item.w}
                      itemStyles={item.widget_styles[0]}
                      x={item.x}
                    />
                  ) : // item.widget_id == "7"
                  item.widgetInfo.name === "groups" ? (
                    <JoinWidget
                      widgetId={Number(item.widget_id)}
                      height={item.h}
                      width={item.w}
                      itemStyles={item.widget_styles[0]}
                      x={item.x}
                    />
                  ) : // item.widget_id == "8"
                  item.widgetInfo.name === "eventList" ? (
                    <EventListWidget
                      widgetId={Number(item.widget_id)}
                      itemStyles={item.widget_styles[0]}
                      height={item.h}
                      width={item.w}
                      x={item.x}
                    />
                  ) : // : item.widgetInfo.name === "watch_player" ? (
                  //   <WatchPlayerWidget
                  //     widgetId={Number(item.widget_id)}
                  //     height={item.h}
                  //     width={item.w}
                  //     itemStyles={item.widget_styles[0]}
                  //     x={item.x}
                  //   />
                  // )
                  item.widgetInfo.name === "chat" ? (
                    <ChatWidget
                      widgetId={Number(item.widget_id)}
                      height={item.h}
                      width={item.w}
                      itemStyles={item.widget_styles[0]}
                      x={item.x}
                    />
                  ) : item.widgetInfo.name === "notes" ? (
                    <NotesWidget
                      widgetId={Number(item.widget_id)}
                      height={item.h}
                      width={item.w}
                      itemStyles={item.widget_styles[0]}
                      x={item.x}
                    />
                  ) : item.widgetInfo.name === "sermon" ? (
                    <SermonWidget
                      widgetId={Number(item.widget_id)}
                      height={item.h}
                      width={item.w}
                      itemStyles={item.widget_styles[0]}
                      x={item.x}
                    />
                  ) : item.widgetInfo.name === "column" ? (
                    <ColumnWidget
                      widgetId={Number(item.widget_id)}
                      itemStyles={item.widget_styles[0]}
                      height={item.h}
                      width={item.w}
                      x={item.x}
                    />
                  ) : item.widgetInfo.name === "row" ? (
                    <RowWidget
                      widgetId={Number(item.widget_id)}
                      itemStyles={item.widget_styles[0]}
                      height={item.h}
                      width={item.w}
                      x={item.x}
                    />
                  ) : item.widgetInfo.name === "default_widget" ? (
                    <CustomWidget
                      widgetId={Number(item.widget_id)}
                      itemStyles={item.widget_styles[0]}
                      height={item.h}
                      width={item.w}
                      x={item.x}
                    />
                  ) : item.widgetInfo.name === "watch" ? (
                    <SingleWidget
                      widgetId={Number(item.widget_id)}
                      itemStyles={item.widget_styles[0]}
                      height={item.h}
                      width={item.w}
                      x={item.x}
                    />
                  ) : null
                }
              </div>
            );
          })}
      </GridLayout>
    </>
  );
};

export default WatchGrid;
